.center {
  text-align: center;
}

.rignt {
  text-align: right;
}

.left {
  text-align: left;
}

.small {
  font-size: 14px;
}

.medium {
  font-size: 16px;
}

.big {
  font-size: 16px;
}