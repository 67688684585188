.buy-tickets {
  .payment{
    padding: 30px 50px;
  }

  .visa{
    font-size: 40px;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 40px;
    color: #fff;
  }

  .payment-form{
    padding: 30px;
    width: 55%;
    background-color: rgba(0,0,0,0.9);
    border-radius: 15px;
    margin-bottom: 30px;
    
    label{
      color: rgba(255,255,255,0.5);
    }
  
    input{
      color: #fff;
      font-size: 21px;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(255,255,255,0.5);
    }
  }

  .cvv{
    float: right;
    width: 60px;
  }

  .cvv input{
    text-align: center;
  }

  .expiration{
    display: inline-block;
  }

  .tickets-order{
    font-size: 0;
  }

  .tickets-list{
    font-size: 16px;
    display: inline-block;
    width: 55%;
    padding-left: 50px;
    padding-top: 30px;
    vertical-align: top;
  }

  h6{
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 30px;
  }

  input{
    margin-bottom: 30px;
  }

  .order{
    padding: 30px;
    vertical-align: top;
    font-size: 15px;
    display: inline-block;
    width: 45%;
  }

  .inner{
    padding: 30px;
    background-color: #f0f0f0;
    border-radius: 15px;
    font-weight: 500;
  }

  .tickets-types{
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    margin: 10px 0;
  }

  .total{
    color: rgba(0,0,0,0.5);
  }

  .inner .row{
    margin: 15px 0;
    font-size: 0;
  }

  .col-6{
    display: inline-block;
    width: 50%;
    font-size: 20px;
  }

  .col-3{
    display: inline-block;
    width: 25%;
    font-size: 15px;
    text-align: right;
  }

  .sum{
    float: right;
    vertical-align: middle;
    font-size: 18px;
    color: #000;
  }

  .header{
    background-color: #333;
    padding: 35px 100px;
  }

  .header.cover{
    background:  0 0 no-repeat;
    background-size: 100% auto;
  }

  .user-actions{
    text-align: right;

  }

  .user-actions .router-link{
    font-size: 14px;
    color: #fff;
  }

  .user-actions i {
    font-size: 18px;
  }

  .date{
    font-size: 20px;
    font-weight: 500;
    color: rgba(255,255,255,0.5);
  }

  .title{
    font-size: 31px;
    color: #fff;
    font-weight: 700;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .header{
      padding: 35px;
    }

    .title {
        font-size: 25px;
        word-wrap: break-word;
    }

    .date{
      font-size: 16px;
    }

    .tickets-list,
    .order,
    .payment-form{
      width: 100%;
      padding: 30px;
    }

  }


  @media (max-width: 767px) {
    .header{
      padding: 20px;
    }

    .header.cover {
        background-size: auto;
    }

    .event-title{
      padding-top: 10px;
    }

    .title {
        font-size: 25px;
        word-wrap: break-word;
    }

    .date{
      font-size: 16px;
    }
    
    .tickets-list,
    .order,
    .payment-form{
      width: 100%;
      padding: 15px;
    }

  }

  @media (max-width: 480px) {
    .payment{
      padding: 15px;
    }

    .cvv{
      float: none;
      margin: 0 auto;
    }

    .expiration{
      width: 100%;
    }
  }
}