.sign {
  .main,
  .footer{
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 130;
    text-align: center;
  }

  .main {
    z-index: 131;
  }

  h1{
    font-weight: 700;
    font-size: 55px;
    color: #fff;
  }

  .main{
    top: calc(50% - 100px);

    p{
      font-size: 25px;
      color: #fff;
      margin: 20px 0;

      @media (max-width: 767px) {
        font-size: 22px;
        line-height: 1.4em;
        padding: 0 5%;
      }
    }
  }

  .footer{
    bottom: 40px;
    
    a{
      color: #23404b;
      padding: 10px;
      display: inline-block;
    }
  }

  .homepage-hero-module {
    border-right: none;
    border-left: none;
    z-index: 2;
    position: relative;
  }

  .no-video .video-container video,
  .touch .video-container video {
      display: none;
  }
  .no-video .video-container .poster,
  .touch .video-container .poster {
      display: block !important;
  }
  .video-container {
    position: relative;
    bottom: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #000;

    .poster img {
      width: 100%;
      bottom: 0;
      position: absolute;
      min-width: 100%; 
      min-height: 100%;
    }

    .filter {
      z-index: 100;
      position: absolute;
      background: rgba(0, 0, 0, 0.4);
      width: 100%;
    }

    video {
      position: absolute;
      z-index: 0;
      bottom: 0;
      
      &.fillWidth {
        min-width: 100%; 
        min-height: 100%;
      }
    }
  }
}