:root {
	// Color Variables:
	--accent-color: #0044ff;
	--light-accent-color: #31ccbb;

	--soft-blue: #123bed;

	--background-color: #ffffff; //#fdfcff;

	--font-family: 'Open Sans', sans-serif;
	--font-family-title: 'Poppins', 'Comfortaa', sans-serif;
}
