/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.wrap-static {
  background: var(--background-color);
  overflow: hidden; }
  .wrap-static header {
    z-index: 20 !important; }

.wrap-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 84px);
  min-height: 700px; }
  @media (max-width: 575.8px) {
    .wrap-box {
      min-height: 600px; } }

.box {
  margin: 0 auto;
  position: relative; }

.content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(80px) translateY(-30px);
  max-width: 202px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #575757; }
  @media (max-width: 575.8px) {
    .content {
      transform: translate(-50%, -50%);
      max-width: 100%;
      width: 230px;
      text-align: center; } }
  .content strong {
    color: #f1635c; }
  .content a,
  .content button {
    width: 129px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    background: #4f4f4f;
    border-radius: 2px;
    margin-bottom: 10px;
    color: #ffffff;
    transition: background 0.35s ease; }
    @media (max-width: 575.8px) {
      .content a,
      .content button {
        margin-left: auto;
        margin-right: auto; } }
    .content a:hover,
    .content button:hover {
      background: rgba(79, 79, 79, 0.7); }
    .content a:active,
    .content button:active {
      background: rgba(79, 79, 79, 0.5); }

.empty {
  position: relative;
  left: auto;
  top: auto;
  transform: translate(0, 0); }
