.wrapNotification {
  position: relative;
  z-index: 100005;
}

.Notifications {
  position: fixed;
  z-index: 10;

  &_top {
    &-right {
      top: 1em;
      right: 1em;
    }

    &-left {
      top: 1em;
      left: 1em;
    }

    &-center {
      top: 1em;
      left: calc(50%);
      transform: translateX(-50%);
    }
  }

  &_bottom {
    &-right {
      right: 1em;
      bottom: 1em;
    }

    &-left {
      left: 1em;
      bottom: 1em;
    }

    &-center {
      bottom: 1em;
      left: calc(50%);
      transform: translateX(-50%);
    }

    @media (max-width: 991px) {}
  }
}