/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 16px;
  padding: 8px 0;
  background: var(--background-color);
  z-index: 105;
  transition: transform 1s; }
  .Component p {
    font-size: 16px;
    margin: 0; }
    @media (max-width: 575.8px) {
      .Component p {
        font-size: 10px;
        line-height: 19px;
        margin-bottom: 10px;
        text-align: center; } }

.Component_hidden {
  transform: translateY(100%); }

.Container {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 575.8px) {
    .Container {
      padding: 0 10px 0; } }
  @media (max-width: 575.8px) {
    .Container {
      flex-direction: column; } }

.Link {
  text-decoration: underline;
  color: var(--accent-color); }
  @media (max-width: 575.8px) {
    .Link {
      margin: 10px auto; } }

.Button {
  min-width: 135px;
  margin-left: 45px; }
  @media (max-width: 575.8px) {
    .Button {
      padding: 2px 0px;
      min-width: 124px;
      margin-left: 0px;
      font-size: 9px;
      line-height: 16px; } }
