.wrap-setting {
  border-top: 1px solid rgba(151, 151, 151, 0.26);
  margin-top: 5px;
  padding: 10px 0;
  margin-bottom: -10px;
  position: relative;
  @media (max-width: 768px) {
    padding: 5px 0 5px;

  }
}

.items {
  display: flex;
  justify-content: space-between;
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;

  > span {
    margin: 0 15px;
  }
}

.wrap-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  @media (max-width: 1200px) {
    padding-right: 40px;
  }


}

.nav {
  flex-grow: 1;
  display: flex;
  font-size: 13px;
  font-family: var(--font-family-title);
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  align-items: center;

  &:hover {
    span {
      color: var(--accent-color);
    }

    svg {
      transform: scale(1)
    }

    path {
      fill: var(--accent-color);
    }
  }

  span {
    color: #8C8C8C;
    transition: all .35s ease;
  }

  svg {
    transform: scale(.8);
    transition: all .35s;
    margin-right: 10px;
  }

  path {
    transition: fill .35s ease
  }

  @media (max-width: 998px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    font-size: 12px;

    svg {
      min-height: 30px;
      align-items: center;
      //display: none;
      display: flex;
    }
    > span {
      display: none;
    }
  }
}

.active {
  span {
    color: var(--accent-color);
  }

  svg {
    transform: scale(1)
  }

  path {
    fill: var(--accent-color);
  }
}

.icon {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.publish {
  position: absolute;
  top: 0;
  right: 20px;
  height: 44px;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #4CAF50 !important;
  cursor: pointer;

  &:hover {
    color: #fff;
    background: #4CAF50;

    > span {
      opacity: 1;
      transition: transform .09s ease, opacity 0.1s ease;
      transform: translateX(-100%) scale(1);

    }
  }


  > span {
    display: flex;
    color: #fff;
    background: #4CAF50;
    height: 44px;
    position: absolute;
    transform: translateX(0) scale(.1);
    left: 0;
    opacity: 0;
    align-items: center;
    white-space: nowrap;
    padding: 0 20px;

  }

  svg {
    flex-shrink: 0;
  }

  @media (max-width: 1200px) {
    right: 0;
  }
  @media (max-width: 998px) {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      display: none;
    }
  }
  @media (max-width: 768px) {
    height: 41px;
    width: 41px;
  }
  @media (max-width: 576px) {
    width: 35px;
    height: 35px;
  }
}

.draft {
  background: var(--accent-color) !important;

  .publish-text {
    background: var(--accent-color);

  }

  svg circle {
    fill: var(--accent-color);
  }
}
