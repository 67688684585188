/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.input-wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px; }
  .input-wrap input::placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }

.ticketBackground {
  position: relative;
  display: inline-flex;
  cursor: pointer; }
  .ticketBackground span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    z-index: 10; }
  .ticketBackground svg {
    z-index: 1; }
  .ticketBackgroundSmall {
    transform: scale(0.7);
    margin-right: -10px; }

.wrap-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px; }
  @media (max-width: 1199.8px) {
    .wrap-input {
      grid-template-columns: 1fr;
      grid-column-gap: 0; } }

.labelInput {
  font-size: 16px;
  line-height: 28px;
  color: #8c8c8c; }
  @media (max-width: 575.8px) {
    .labelInput {
      font-size: 14px;
      line-height: 25px; } }

.defaultInput {
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center; }
  @media (max-width: 575.8px) {
    .defaultInput {
      width: 100%; } }

input[type='checkbox'] ~ label {
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  color: #8c8c8c;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  flex-shrink: 0;
  line-height: 28px;
  align-items: center; }
  @media (max-width: 575.8px) {
    input[type='checkbox'] ~ label {
      font-size: 14px;
      line-height: 28px;
      align-items: flex-start; } }

.checkbox {
  display: none; }

.checkbox:checked + label:after {
  opacity: 1; }

.checkbox + label:after {
  content: '\2714';
  opacity: 0;
  position: absolute;
  transform: scale(0.7) translateY(calc(-50% - 5px));
  color: #fff;
  left: 4px;
  top: 50%;
  transition: opacity .35s ease; }
  @media (max-width: 575.8px) {
    .checkbox + label:after {
      transform: scale(0.7) translateX(3px) translateY(2px);
      top: 3px; } }

.checkbox + label:before {
  content: "";
  border: 0.1em solid #8c8c8c;
  border-radius: 0.2em;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 0;
  padding-bottom: 0;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  flex-shrink: 0;
  transition: .2s; }
  @media (max-width: 575.8px) {
    .checkbox + label:before {
      margin-top: 7px;
      margin-right: 15px; } }

.checkbox + label {
  position: relative; }

.checkbox + label:active:before {
  transform: scale(0); }

.checkbox:checked + label:before {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }

.checkbox:disabled + label:before {
  transform: scale(1);
  border-color: #aaa; }

.checkbox:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb; }

@media (max-width: 575.8px) {
  input, textarea, select {
    font-size: 16px; } }

.simpleSelect {
  position: relative;
  margin-bottom: 20px; }

.simpleInput {
  position: relative;
  margin-bottom: 20px; }
  .simpleInput label {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 28px !important;
    color: #8C8C8C !important; }
    .simpleInput label span {
      color: var(--accent-color);
      position: relative;
      top: -3px;
      left: 2px; }
  .simpleInput input {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    background: #FFFFFF;
    font-size: 17px;
    padding: 10px;
    line-height: 28px; }
    .simpleInput input:focus, .simpleInput input:active {
      border: 1px solid #8C8C8C !important; }
  .simpleInput textarea {
    min-height: 200px;
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    background: #FFFFFF;
    font-size: 17px;
    line-height: 28px;
    border-radius: 2px;
    padding: 10px; }
    .simpleInput textarea:focus, .simpleInput textarea:active {
      border: 1px solid #8C8C8C !important; }
  .simpleInput select {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    transition: border .35s ease;
    font-size: 17px;
    padding: 10px;
    line-height: 28px;
    position: relative; }
    .simpleInput select option {
      width: 30px; }
    .simpleInput select:focus, .simpleInput select:active {
      border: 1px solid #8C8C8C !important; }

.simpleInputBorder {
  margin-bottom: 0; }
  .simpleInputBorder input {
    border: 1px solid #8C8C8C !important; }

.hideBorder input {
  border: 1px solid transparent !important;
  transition: border .35s ease; }

.borderNone {
  border: 1px solid transparent !important; }
  .borderNone * {
    border: none; }

.column {
  display: flex;
  flex-direction: column; }

.fz17 {
  font-size: 17px !important; }
  .fz17 * {
    font-size: 17px !important; }

.radio-control {
  display: flex;
  color: #8C8C8C;
  font-style: normal;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase; }
  @media (max-width: 767.8px) {
    .radio-control {
      flex-direction: column; } }
  .radio-control label {
    display: flex;
    width: 50%;
    padding: 0 20px;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767.8px) {
      .radio-control label {
        width: 100%; } }
  .radio-control input[type=radio] {
    position: absolute;
    left: -1000px;
    opacity: 0;
    visibility: hidden; }

.radio {
  width: 17px;
  height: 17px;
  border-radius: 10em;
  border: 1.2px solid #8C8C8C;
  transition: border .35s ease, color .35s ease;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px; }
  .radio span {
    background: var(--accent-color);
    width: 7px;
    height: 7px;
    transform: scale(0);
    display: block;
    opacity: 0;
    border-radius: 10em;
    transition: opacity .35s ease, transform .35s ease; }

.active {
  color: var(--accent-color); }
  .active .radio {
    border: 1.2px solid var(--accent-color); }
    .active .radio span {
      transform: scale(1.1);
      opacity: 1; }

.input_outline {
  position: absolute;
  bottom: 0;
  width: 97%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px; }
  .input_outline span {
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    background: #8C8C8C; }

input[type='text'], input[type='password'] {
  transition: border .34s ease; }
  input[type='text']:focus, input[type='text']:active, input[type='password']:focus, input[type='password']:active {
    border: 1px solid #8C8C8C !important; }

.zIndex10 {
  z-index: 10 !important; }

/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Transition-enter {
  opacity: 0.01; }

.Transition-enter-active {
  opacity: 1;
  transition: 300ms ease-in; }

.Transition-exit {
  opacity: 1; }

.Transition-exit-active {
  opacity: 0.01;
  transition: 300ms ease-out; }

.italic {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px; }

.timePickerMaxIndex {
  z-index: 999999 !important; }

@keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.08); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.05); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes pulse-shadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes pulse-shadow {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.pulse-icon {
  display: block;
  border-radius: 10em;
  cursor: pointer;
  animation: pulse-shadow 2s infinite; }
  .pulse-icon:hover {
    animation: none; }

.warning {
  display: block;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  position: relative;
  background: var(--accent-color);
  color: #4b4b4b;
  border-radius: 2px;
  margin: 10px 0; }
  .warning p {
    color: #fff; }
  .warning.error {
    background: rgba(252, 68, 88, 0.5) !important; }

.pulse span,
.pulse path {
  animation-name: pulse_animation;
  animation-duration: 2000ms;
  transform-origin: center;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.custom-btn {
  overflow: hidden;
  position: relative; }

.custom-btn:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  z-index: 1;
  -webkit-transition: ease 0.2s;
  transition: ease 0.2s; }

.custom-btn:after {
  content: '';
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: #fff;
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-animation-name: slideme;
  animation-name: slideme;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0px; }
  40% {
    left: 110%;
    margin-left: 80px; }
  100% {
    left: 110%;
    margin-left: 80px; } }

.wrap-select-custom {
  width: 100px !important;
  margin: 0;
  margin-bottom: 0 !important;
  flex-shrink: 0; }
  .wrap-select-custom input {
    height: 35px !important;
    margin-top: 0; }
  .wrap-select-custom span {
    right: 0px; }

.opacity0 {
  opacity: 0 !important; }

.titleWithSelect {
  display: flex;
  align-items: center; }
  .titleWithSelect span {
    flex-shrink: 0;
    display: inline-flex;
    margin-right: 20px; }

.require {
  position: relative;
  right: -4px;
  top: -2px;
  color: var(--accent-color); }

.container70 {
  padding: 0 70px; }
  @media (max-width: 1023.8px) {
    .container70 {
      padding: 0; } }

.color-green {
  color: #4caf50; }

.justify-center {
  display: flex;
  flex-direction: column;
  align-items: center; }

.content-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.max969 {
  max-width: 969px;
  margin-left: auto;
  margin-right: auto; }

.fz2550 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center; }

.fz2530 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center; }

.text-left {
  text-align: left !important; }

.fz1830 {
  font-size: 18px;
  line-height: 30px; }
  @media (max-width: 575.8px) {
    .fz1830 {
      font-size: 16px;
      line-height: 26px; } }

.fz1628 {
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 575.8px) {
    .fz1628 {
      font-size: 14px;
      line-height: 28px; } }
  .fz1628 p,
  .fz1628 a {
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 575.8px) {
      .fz1628 p,
      .fz1628 a {
        font-size: 14px;
        line-height: 28px; } }

.fz1426 {
  font-size: 14px;
  line-height: 26px; }

.fw600 {
  font-weight: 600; }

.fw799 {
  font-weight: 700; }

.c57 {
  color: #575757; }

.btn-orange {
  color: var(--accent-color);
  cursor: pointer; }

.ml10 {
  margin-left: 10px; }

.mr10 {
  margin-right: 10px; }

.ml20 {
  margin-left: 20px; }
  @media (max-width: 575.8px) {
    .ml20 {
      margin-left: 15px; } }

.mr20 {
  margin-right: 20px; }
  @media (max-width: 575.8px) {
    .mr20 {
      margin-right: 15px; } }

.ml30 {
  margin-left: 30px; }
  @media (max-width: 575.8px) {
    .ml30 {
      margin-left: 20px; } }

.mr30 {
  margin-right: 30px; }
  @media (max-width: 575.8px) {
    .mr30 {
      margin-right: 20px; } }

.mt30 {
  margin-top: 30px; }
  @media (max-width: 575.8px) {
    .mt30 {
      margin-top: 15px; } }

.mb30 {
  margin-bottom: 30px; }
  @media (max-width: 575.8px) {
    .mb30 {
      margin-bottom: 15px; } }

.mt50 {
  margin-top: 50px; }
  @media (max-width: 575.8px) {
    .mt50 {
      margin-top: 20px; } }

.mt50i {
  margin-top: 50px !important; }
  @media (max-width: 575.8px) {
    .mt50i {
      margin-top: 20px !important; } }

.mb50 {
  margin-bottom: 50px; }
  @media (max-width: 575.8px) {
    .mb50 {
      margin-bottom: 20px; } }

.mt20 {
  margin-top: 20px; }
  @media (max-width: 575.8px) {
    .mt20 {
      margin-top: 15px; } }

.mb20 {
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .mb20 {
      margin-bottom: 15px; } }

.mb5 {
  margin-bottom: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt0 {
  margin-top: 0 !important;
  flex-grow: 1; }

.mt-15 {
  margin-top: -15px !important; }

.w100 {
  width: 100% !important; }

.pt20 {
  padding-top: 20px; }
  @media (max-width: 575.8px) {
    .pt20 {
      padding-top: 15px; } }

.pb20 {
  padding-bottom: 20px; }
  @media (max-width: 575.8px) {
    .pb20 {
      padding-bottom: 15px; } }

.p0 {
  padding: 0 !important; }

.m_auto {
  margin-right: auto;
  margin-left: auto; }

.pt50 {
  padding-top: 50px; }
  @media (max-width: 575.8px) {
    .pt50 {
      padding-top: 20px; } }

.pt30 {
  padding-top: 30px; }
  @media (max-width: 575.8px) {
    .pt30 {
      padding-top: 15px; } }

.pb100 {
  padding-bottom: 100px; }
  @media (max-width: 575.8px) {
    .pb100 {
      padding-bottom: 50px; } }

.pb50 {
  padding-bottom: 50px; }
  @media (max-width: 575.8px) {
    .pb50 {
      padding-bottom: 20px; } }

.pb30 {
  padding-bottom: 30px; }
  @media (max-width: 575.8px) {
    .pb30 {
      padding-bottom: 15px; } }

.title-invent {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #575757;
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .title-invent {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 17px; } }

.description-invent {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8c8c8c;
  line-height: 28px; }
  .description-invent a {
    color: var(--accent-color) !important; }
  @media (max-width: 575.8px) {
    .description-invent {
      font-size: 14px;
      line-height: 28px; } }

.caption {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  color: #575757;
  z-index: 5; }
  .caption b {
    display: flex;
    margin-top: -5px; }
    .caption b span {
      right: 4px !important; }
  @media (max-width: 575.8px) {
    .caption {
      font-size: 22px;
      line-height: 30px; } }

.wrap-loader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8); }

.wrap-loader {
  margin: 20px 0;
  text-align: center;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media (max-width: 767.8px) {
    .wrap-loader svg {
      transform: scale(0.7); } }

.btn-green {
  background: #4caf50;
  border-radius: 2px;
  width: 262px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  transition: background 0.34s;
  font-size: 14px;
  text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .btn-green {
      border-radius: 2px;
      width: 100%;
      height: 31px;
      font-weight: 600;
      font-size: 10px;
      max-width: 256px;
      line-height: 26px; } }

.btn-green:hover {
  background: rgba(76, 175, 80, 0.7); }

.btn-green:active {
  background: rgba(76, 175, 80, 0.5); }

.button-orange {
  background: var(--accent-color);
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  max-width: 500px;
  height: 40px;
  transform: scale(1);
  transition: transform 0.25s ease-in-out; }
  .button-orange:hover {
    transform: scale(1.05); }
  .button-orange:disabled {
    background: #8c8c8c;
    cursor: not-allowed; }

.button-orange-light {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-accent-color) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--accent-color) !important;
  transition: color 0.35s ease, background 0.35s ease !important;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px; }
  .button-orange-light:disabled {
    background: #8c8c8c;
    cursor: not-allowed;
    color: #fff; }

.fz1728 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px; }

.c8c {
  color: #8c8c8c; }

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.relative {
  position: relative; }

.scroll {
  overflow-y: scroll !important; }
  .scroll::-webkit-scrollbar {
    width: 2px; }
  .scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .scroll::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }

.ticketIcon {
  position: relative;
  display: inline-flex; }
  .ticketIcon strong {
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    top: 50%;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff; }

.textOnButton {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }
  .textOnButton span {
    color: var(--accent-color);
    margin-right: 5px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px; }

.modalType {
  background: var(--background-color) !important;
  max-width: 962px; }

.CloseBtn {
  top: 0;
  right: 0;
  position: absolute;
  opacity: 0.9;
  display: inline-flex;
  transition: 0.2s;
  transform: translate(50%, -50%);
  z-index: 20; }
  .CloseBtn svg {
    opacity: 1 !important; }
  .CloseBtn:after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 10em; }
  .CloseBtn:hover {
    opacity: 1; }
  @media (max-width: 576px) {
    .CloseBtn {
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #333; } }

b {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }

.inputWithCalendar {
  position: relative; }
  .inputWithCalendar input {
    padding-right: 20px; }
  .inputWithCalendar svg,
  .inputWithCalendar span {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #8c8c8c;
    font-weight: 700; }
    .inputWithCalendar svg path,
    .inputWithCalendar span path {
      fill: #8c8c8c; }

.text-orange {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--accent-color); }

.color-orange {
  color: var(--accent-color) !important; }

.overlay-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10; }

.rememberCheck {
  display: flex;
  align-items: center; }
  .rememberCheck label {
    margin-left: 10px;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }

.signWithApp {
  display: flex;
  justify-content: center; }
  .signWithApp button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 10em;
    justify-content: center;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03);
    transition: box-shadow .35s ease; }
    .signWithApp button:hover, .signWithApp button:active {
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1); }

.fb {
  background: #3B5998; }

.imageForm {
  position: absolute;
  left: calc(500px + 200px);
  top: 120px; }
  @media (max-width: 1350px) {
    .imageForm {
      right: 20px;
      z-index: 1;
      left: auto;
      opacity: .3; } }
  @media (max-width: 578px) {
    .imageForm {
      display: none; } }

.needHelp a {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: var(--accent-color); }
