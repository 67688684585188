/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.custom-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity .35s ease;
  color: #fff; }
  .custom-btn:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important; }
  .custom-btn:hover {
    opacity: .7; }
  .custom-btn:active {
    opacity: .5; }
  @media (max-width: 575.8px) {
    .custom-btn {
      width: 100% !important; } }

.Main {
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  margin: 0 10px;
  color: #fff; }
  .Main-big {
    border-radius: 27px;
    padding: 12px 40px;
    min-height: 55px; }
  .Main-medium {
    border-radius: 24px;
    padding: 10px 30px;
    min-height: 48px;
    font-size: 18px; }
  .Main-small {
    border-radius: 21px;
    padding: 10px 20px 8px;
    min-height: 42px;
    font-size: 16px; }
  .Main-rounded {
    border-radius: 50%; }
  .Main-primary {
    background: #0078ff; }
    .Main-primary[disabled] {
      background: #99c9ff; }
  .Main-danger {
    background: #E50000; }
    .Main-danger[disabled] {
      background: #ff7f7f; }
  .Main-warning {
    background: #ffc107; }
    .Main-warning[disabled] {
      background: #ffe7a0; }
  .Main-grey {
    background: #d9d9d9;
    color: #333333; }
    .Main-grey[disabled] {
      background: white; }
  .Main-link {
    background: transparent; }
    .Main-link[disabled] {
      color: #99c9ff; }

.loading:disabled {
  background: transparent !important; }
