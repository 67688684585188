/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  padding: 25px 0;
  background: var(--background-color);
  z-index: 20;
  transition: box-shadow 300ms ease-in-out; }
  @media (max-width: 575.8px) {
    .Component {
      padding: 17px 0 10px; } }

.Shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); }

.Container {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 575.8px) {
    .Container {
      padding: 0 10px 0; } }
  @media (max-width: 767.8px) {
    .Container {
      padding: 3px 15px; } }

.Logo {
  flex-basis: 20%;
  cursor: pointer; }
  @media (max-width: 1439.8px) {
    .Logo {
      flex-basis: unset; } }
  @media (max-width: 767.8px) {
    .Logo svg {
      height: 20px;
      width: auto; } }
  @media (max-width: 575.8px) {
    .Logo {
      margin-bottom: 8px;
      margin-left: 4px; } }

.Nav {
  text-align: center;
  flex-grow: 1;
  margin-top: 5px; }
  .Nav a {
    color: #575757;
    line-height: 28px;
    font-size: 16px;
    margin-right: 40px;
    border-bottom: 1px solid transparent; }
    .Nav a:last-child {
      margin-right: 0; }
    .Nav a:hover {
      border-bottom-color: var(--accent-color); }
  @media (max-width: 1199.8px) {
    .Nav {
      display: none; } }

.Actions {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.SignBtn {
  margin-left: 40px;
  min-width: 151px;
  height: 45px;
  font-size: 16px;
  font-weight: 400; }

.TicketButton {
  width: 270px;
  height: 60px;
  margin-left: 30px;
  font-weight: 600;
  font-size: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 26px;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  transform: scale(1);
  transition: transform 0.25s ease-in-out;
  color: var(--background-color); }
  .TicketButton span {
    position: relative;
    z-index: 2; }
  .TicketButton svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
    .TicketButton svg path {
      transition: 0.2s; }
  .TicketButton:hover {
    transform: scale(1.05); }
  .TicketButton[disabled],
  .TicketButton:active svg path {
    cursor: not-allowed; }
  @media (max-width: 1439.8px) {
    .TicketButton {
      width: 220px;
      height: 50px; } }
  @media (max-width: 767.8px) {
    .TicketButton {
      width: 135px;
      height: 30px;
      font-size: 10px; } }
  @media (max-width: 575.8px) {
    .TicketButton {
      margin-left: 0px; } }

@media (max-width: 575.8px) {
  .LanguageSwitcher {
    padding: 0 20px; } }
