/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.wrap-dots {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.dot {
  background: #f6f4f1;
  width: 45px;
  height: 45px;
  position: relative;
  border-radius: 10em; }
  .dot.dotTicket {
    width: 36px;
    height: 36px; }
    .dot.dotTicket span:after, .dot.dotTicket span:before {
      width: 6px;
      height: 6px; }
      @media (max-width: 575.8px) {
        .dot.dotTicket span:after, .dot.dotTicket span:before {
          width: 8px;
          height: 8px; } }
  .dot span:after, .dot span:before {
    width: 8px;
    height: 8px;
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    content: '';
    display: block;
    background: #f6f4f1;
    border-radius: 50%; }

.dot span:nth-child(1):after {
  transform: translateY(37px); }

.dot span:nth-child(1):before {
  transform: translateY(-37px); }

.dot span:nth-child(2):after {
  transform: translateY(53px); }

.dot span:nth-child(2):before {
  transform: translateY(-53px); }

.dot span:nth-child(3):after {
  transform: translateY(69px); }

.dot span:nth-child(3):before {
  transform: translateY(-69px); }

.dot span:nth-child(4):after {
  transform: translateY(85px); }

.dot span:nth-child(4):before {
  transform: translateY(-85px); }

.dot span:nth-child(5):after {
  transform: translateY(101px); }

.dot span:nth-child(5):before {
  transform: translateY(-101px); }

.dot span:nth-child(6):after {
  transform: translateY(117px); }

.dot span:nth-child(6):before {
  transform: translateY(-117px); }

.dot span:nth-child(7):after {
  transform: translateY(133px); }

.dot span:nth-child(7):before {
  transform: translateY(-133px); }

.dot span:nth-child(8):after {
  transform: translateY(149px); }

.dot span:nth-child(8):before {
  transform: translateY(-149px); }

.dot span:nth-child(9):after {
  transform: translateY(165px); }

.dot span:nth-child(9):before {
  transform: translateY(-165px); }

.dot span:nth-child(10):after {
  transform: translateY(181px); }

.dot span:nth-child(10):before {
  transform: translateY(-181px); }

.dotTicket span:nth-child(1):after {
  transform: translateY(28px); }

.dotTicket span:nth-child(1):before {
  transform: translateY(-28px); }

.dotTicket span:nth-child(2):after {
  transform: translateY(44px); }

.dotTicket span:nth-child(2):before {
  transform: translateY(-44px); }

.dotTicket span:nth-child(3):after {
  transform: translateY(60px); }

.dotTicket span:nth-child(3):before {
  transform: translateY(-60px); }

.dotTicket span:nth-child(4):after {
  transform: translateY(76px); }

.dotTicket span:nth-child(4):before {
  transform: translateY(-76px); }

.dotTicket span:nth-child(5):after {
  transform: translateY(92px); }

.dotTicket span:nth-child(5):before {
  transform: translateY(-92px); }

.dotTicket span:nth-child(6):after {
  transform: translateY(108px); }

.dotTicket span:nth-child(6):before {
  transform: translateY(-108px); }

.dotTicket span:nth-child(7):after {
  transform: translateY(124px); }

.dotTicket span:nth-child(7):before {
  transform: translateY(-124px); }

.dotTicket span:nth-child(8):after {
  transform: translateY(140px); }

.dotTicket span:nth-child(8):before {
  transform: translateY(-140px); }

.dotTicket span:nth-child(9):after {
  transform: translateY(156px); }

.dotTicket span:nth-child(9):before {
  transform: translateY(-156px); }

.dotTicket span:nth-child(10):after {
  transform: translateY(172px); }

.dotTicket span:nth-child(10):before {
  transform: translateY(-172px); }
