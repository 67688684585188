/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  position: relative;
  z-index: 2; }
  .Component_visible {
    z-index: 20; }

.Dropdown {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  min-width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 3px rgba(142, 142, 142, 0.09);
  padding: 75px 10px 27px;
  z-index: 5; }
  .DropdownClose {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0; }
  @media (max-width: 767.8px) {
    .Dropdown {
      position: fixed;
      z-index: 25;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex; }
      .DropdownClose {
        display: block;
        top: 10px;
        right: 10px; } }
