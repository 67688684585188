/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  width: fit-content;
  margin: auto; }

.Calendar {
  display: grid;
  grid-template-columns: repeat(7, 42px);
  grid-gap: 10px 0; }

.Month {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.7em; }
  .Month button:first-of-type {
    transform: rotate(180deg); }

.Day {
  font-size: 16px;
  line-height: 1.7em;
  padding: 5px 10px;
  cursor: pointer;
  text-transform: capitalize;
  text-align: center;
  color: #575757; }
  .Day_today {
    color: var(--accent-color); }
  .Day:disabled, .Day_disabled {
    color: #ddd; }
  .Day_active {
    background: var(--accent-color);
    color: #575757; }
  .Day_active-first {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px; }
  .Day_active-last {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px; }
