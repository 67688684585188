/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  background: var(--background-color); }
  .Component_white_bg {
    background: #fff; }

.Container {
  padding: 0 15px 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); }
  @media (max-width: 575.8px) {
    .Container {
      padding: 0 10px 0; } }
  @media (max-width: 1199.8px) {
    .Container {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 767.8px) {
    .Container {
      display: block;
      padding: 0; } }

.Main {
  border-bottom: 1px solid rgba(140, 140, 140, 0.5);
  padding-bottom: 33px;
  padding-top: 78px; }
  @media (max-width: 1023.8px) {
    .Main {
      padding-top: 40px;
      padding-bottom: 5px; } }
  @media (max-width: 767.8px) {
    .Main {
      padding-top: 12px; } }
  .Main h4,
  .Main h4 a,
  .Main a,
  .Main address {
    color: #575757;
    line-height: 28px;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 20px; }
  .Main a {
    text-transform: none;
    margin-bottom: 10px;
    border-bottom: 1px solid transparent;
    width: fit-content; }
    .Main a:hover {
      color: var(--accent-color);
      border-bottom-color: var(--accent-color); }
  .Main address {
    text-transform: none;
    margin-bottom: 10px; }
    .Main address span {
      display: block;
      margin-bottom: 20px; }

.Socials {
  display: flex;
  align-items: center; }
  @media (max-width: 767.8px) {
    .Socials {
      justify-content: center; } }
  .Socials a {
    margin-right: 35px;
    border-bottom: none; }
    .Socials a:last-child {
      margin-right: 0; }
    .Socials a svg path {
      transition: 0.2s; }
    .Socials a:hover svg path {
      fill-opacity: 1; }
    @media (max-width: 767.8px) {
      .Socials a {
        margin-bottom: 0; } }

.Additional {
  padding: 35px 15px 35px;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 575.8px) {
    .Additional {
      padding: 35px 10px 35px; } }
  @media (max-width: 1023.8px) {
    .Additional {
      padding-top: 15px;
      padding-bottom: 15px; } }
  @media (max-width: 767.8px) {
    .Additional {
      flex-direction: column; } }
  .Additional p {
    line-height: 1.7em;
    font-size: 16px;
    color: #575757; }
    @media (max-width: 767.8px) {
      .Additional p {
        font-weight: 600;
        font-size: 14px; } }

.PaymentSystems {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767.8px) {
    .PaymentSystems {
      width: 100%; } }
  .PaymentSystems img {
    margin-right: 50px; }
    @media (max-width: 767.8px) {
      .PaymentSystems img {
        margin-right: 0;
        flex-basis: 33.33%; } }

.Contacts svg {
  display: inline-block;
  margin-right: 12px;
  width: 15px;
  height: 15px; }

@media (max-width: 1023.8px) {
  .Contacts {
    font-size: 14px; } }

@media (max-width: 767.8px) {
  .Contacts {
    padding: 10px; }
    .Contacts h4 {
      display: none; } }

@media (max-width: 767.8px) {
  .Nav {
    padding-left: 11px; } }

@media (max-width: 1199.8px) {
  .Footer-custom {
    display: block;
    padding: 0 10px; } }

.MadeBy {
  display: flex;
  align-items: center;
  gap: 6px; }
  .MadeBy p {
    font-size: 14px;
    line-height: 100%;
    color: #575757; }

.MadeByLogo {
  margin-right: 10%;
  display: block;
  cursor: pointer; }

.MadeByLogo {
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-size: 14px;
  color: #757e96; }
