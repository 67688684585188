/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.input-wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px; }
  .input-wrap input::placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C; }

.ticketBackground {
  position: relative;
  display: inline-flex;
  cursor: pointer; }
  .ticketBackground span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    z-index: 10; }
  .ticketBackground svg {
    z-index: 1; }
  .ticketBackgroundSmall {
    transform: scale(0.7);
    margin-right: -10px; }

.wrap-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px; }
  @media (max-width: 1199.8px) {
    .wrap-input {
      grid-template-columns: 1fr;
      grid-column-gap: 0; } }

.labelInput {
  font-size: 16px;
  line-height: 28px;
  color: #8c8c8c; }
  @media (max-width: 575.8px) {
    .labelInput {
      font-size: 14px;
      line-height: 25px; } }

.defaultInput {
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center; }
  @media (max-width: 575.8px) {
    .defaultInput {
      width: 100%; } }

input[type='checkbox'] ~ label {
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  color: #8c8c8c;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  flex-shrink: 0;
  line-height: 28px;
  align-items: center; }
  @media (max-width: 575.8px) {
    input[type='checkbox'] ~ label {
      font-size: 14px;
      line-height: 28px;
      align-items: flex-start; } }

.checkbox {
  display: none; }

.checkbox:checked + label:after {
  opacity: 1; }

.checkbox + label:after {
  content: '\2714';
  opacity: 0;
  position: absolute;
  transform: scale(0.7) translateY(calc(-50% - 5px));
  color: #fff;
  left: 4px;
  top: 50%;
  transition: opacity .35s ease; }
  @media (max-width: 575.8px) {
    .checkbox + label:after {
      transform: scale(0.7) translateX(3px) translateY(2px);
      top: 3px; } }

.checkbox + label:before {
  content: "";
  border: 0.1em solid #8c8c8c;
  border-radius: 0.2em;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 0;
  padding-bottom: 0;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  flex-shrink: 0;
  transition: .2s; }
  @media (max-width: 575.8px) {
    .checkbox + label:before {
      margin-top: 7px;
      margin-right: 15px; } }

.checkbox + label {
  position: relative; }

.checkbox + label:active:before {
  transform: scale(0); }

.checkbox:checked + label:before {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }

.checkbox:disabled + label:before {
  transform: scale(1);
  border-color: #aaa; }

.checkbox:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb; }

@media (max-width: 575.8px) {
  input, textarea, select {
    font-size: 16px; } }

.simpleSelect {
  position: relative;
  margin-bottom: 20px; }

.simpleInput {
  position: relative;
  margin-bottom: 20px; }
  .simpleInput label {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 28px !important;
    color: #8C8C8C !important; }
    .simpleInput label span {
      color: var(--accent-color);
      position: relative;
      top: -3px;
      left: 2px; }
  .simpleInput input {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    background: #FFFFFF;
    font-size: 17px;
    padding: 10px;
    line-height: 28px; }
    .simpleInput input:focus, .simpleInput input:active {
      border: 1px solid #8C8C8C !important; }
  .simpleInput textarea {
    min-height: 200px;
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    background: #FFFFFF;
    font-size: 17px;
    line-height: 28px;
    border-radius: 2px;
    padding: 10px; }
    .simpleInput textarea:focus, .simpleInput textarea:active {
      border: 1px solid #8C8C8C !important; }
  .simpleInput select {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    transition: border .35s ease;
    font-size: 17px;
    padding: 10px;
    line-height: 28px;
    position: relative; }
    .simpleInput select option {
      width: 30px; }
    .simpleInput select:focus, .simpleInput select:active {
      border: 1px solid #8C8C8C !important; }

.simpleInputBorder {
  margin-bottom: 0; }
  .simpleInputBorder input {
    border: 1px solid #8C8C8C !important; }

.hideBorder input {
  border: 1px solid transparent !important;
  transition: border .35s ease; }

.borderNone {
  border: 1px solid transparent !important; }
  .borderNone * {
    border: none; }

.column {
  display: flex;
  flex-direction: column; }

.fz17 {
  font-size: 17px !important; }
  .fz17 * {
    font-size: 17px !important; }

.radio-control {
  display: flex;
  color: #8C8C8C;
  font-style: normal;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase; }
  @media (max-width: 767.8px) {
    .radio-control {
      flex-direction: column; } }
  .radio-control label {
    display: flex;
    width: 50%;
    padding: 0 20px;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767.8px) {
      .radio-control label {
        width: 100%; } }
  .radio-control input[type=radio] {
    position: absolute;
    left: -1000px;
    opacity: 0;
    visibility: hidden; }

.radio {
  width: 17px;
  height: 17px;
  border-radius: 10em;
  border: 1.2px solid #8C8C8C;
  transition: border .35s ease, color .35s ease;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px; }
  .radio span {
    background: var(--accent-color);
    width: 7px;
    height: 7px;
    transform: scale(0);
    display: block;
    opacity: 0;
    border-radius: 10em;
    transition: opacity .35s ease, transform .35s ease; }

.active {
  color: var(--accent-color); }
  .active .radio {
    border: 1.2px solid var(--accent-color); }
    .active .radio span {
      transform: scale(1.1);
      opacity: 1; }

.input_outline {
  position: absolute;
  bottom: 0;
  width: 97%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px; }
  .input_outline span {
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    background: #8C8C8C; }

input[type='text'], input[type='password'] {
  transition: border .34s ease; }
  input[type='text']:focus, input[type='text']:active, input[type='password']:focus, input[type='password']:active {
    border: 1px solid #8C8C8C !important; }

.zIndex10 {
  z-index: 10 !important; }

.select-wrap {
  position: relative;
  z-index: 1;
  width: 100%; }

.select {
  width: 100%; }

.zindex10 {
  z-index: 10 !important; }

.options {
  width: 570px;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 3px rgba(222, 222, 222, 0.5);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  opacity: 0;
  bottom: 0px;
  transform: translateY(100%);
  left: -5000px;
  position: absolute;
  max-height: 400px; }
  .options::-webkit-scrollbar {
    width: 2px; }
  .options::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .options::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }
  .options span {
    display: flex;
    align-items: center;
    padding: 5px 0;
    flex-direction: row;
    cursor: pointer; }
    .options span small {
      font-family: var(--font-family);
      font-style: italic;
      font-weight: 300;
      display: inline-flex;
      text-indent: 0;
      transition: color .35s ease, text-indent .35s ease;
      font-size: 16px;
      line-height: 21px;
      color: #8C8C8C; }
    .options span strong {
      transition: opacity .35s ease-in-out, transform .35s ease;
      opacity: 0;
      transform: translateX(-5px);
      display: flex;
      align-items: center; }
    .options span:hover small {
      color: var(--accent-color);
      text-indent: 10px; }
    .options span:hover strong {
      opacity: 1;
      transform: translateX(0); }

.options-scroll {
  overflow-y: auto; }

.show {
  opacity: 1;
  left: 0; }

.input-wrap {
  position: relative; }

.select-arrow {
  position: absolute !important;
  right: 15px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  left: auto !important; }
  .select-arrow svg {
    transition: transform .35s ease; }

.selected small {
  color: var(--accent-color) !important;
  text-indent: 10px !important; }

.selected strong {
  opacity: 1 !important;
  transform: translateX(0) !important; }

.selectArrowActive svg {
  transform: rotate(-90deg) !important; }

.select-trigger {
  font-family: var(--font-family);
  font-style: italic;
  font-weight: 300;
  flex-grow: 1;
  width: 100%;
  font-size: 16px;
  line-height: 21px;
  color: #8C8C8C; }

.b0 {
  border: 1px solid transparent !important; }
