/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Transition-enter {
  opacity: 0.01; }

.Transition-enter-active {
  opacity: 1;
  transition: 300ms ease-in; }

.Transition-exit {
  opacity: 1; }

.Transition-exit-active {
  opacity: 0.01;
  transition: 300ms ease-out; }

.italic {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px; }

.timePickerMaxIndex {
  z-index: 999999 !important; }

@keyframes pulse_animation {
  0% {
    transform: scale(1); }
  30% {
    transform: scale(1); }
  40% {
    transform: scale(1.08); }
  50% {
    transform: scale(1); }
  60% {
    transform: scale(1); }
  70% {
    transform: scale(1.05); }
  80% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes pulse-shadow {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

@keyframes pulse-shadow {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0); } }

.pulse-icon {
  display: block;
  border-radius: 10em;
  cursor: pointer;
  animation: pulse-shadow 2s infinite; }
  .pulse-icon:hover {
    animation: none; }

.warning {
  display: block;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
  position: relative;
  background: var(--accent-color);
  color: #4b4b4b;
  border-radius: 2px;
  margin: 10px 0; }
  .warning p {
    color: #fff; }
  .warning.error {
    background: rgba(252, 68, 88, 0.5) !important; }

.pulse span,
.pulse path {
  animation-name: pulse_animation;
  animation-duration: 2000ms;
  transform-origin: center;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.custom-btn {
  overflow: hidden;
  position: relative; }

.custom-btn:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  z-index: 1;
  -webkit-transition: ease 0.2s;
  transition: ease 0.2s; }

.custom-btn:after {
  content: '';
  display: block;
  width: 30px;
  height: 300px;
  margin-left: 60px;
  background: #fff;
  position: absolute;
  left: -40px;
  top: -150px;
  z-index: 1;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
  -webkit-animation-name: slideme;
  animation-name: slideme;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

@-webkit-keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0px; }
  40% {
    left: 110%;
    margin-left: 80px; }
  100% {
    left: 110%;
    margin-left: 80px; } }

.wrap-select-custom {
  width: 100px !important;
  margin: 0;
  margin-bottom: 0 !important;
  flex-shrink: 0; }
  .wrap-select-custom input {
    height: 35px !important;
    margin-top: 0; }
  .wrap-select-custom span {
    right: 0px; }

.opacity0 {
  opacity: 0 !important; }

.titleWithSelect {
  display: flex;
  align-items: center; }
  .titleWithSelect span {
    flex-shrink: 0;
    display: inline-flex;
    margin-right: 20px; }

.require {
  position: relative;
  right: -4px;
  top: -2px;
  color: var(--accent-color); }

.container70 {
  padding: 0 70px; }
  @media (max-width: 1023.8px) {
    .container70 {
      padding: 0; } }

.color-green {
  color: #4caf50; }

.justify-center {
  display: flex;
  flex-direction: column;
  align-items: center; }

.content-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.max969 {
  max-width: 969px;
  margin-left: auto;
  margin-right: auto; }

.fz2550 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center; }

.fz2530 {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center; }

.text-left {
  text-align: left !important; }

.fz1830 {
  font-size: 18px;
  line-height: 30px; }
  @media (max-width: 575.8px) {
    .fz1830 {
      font-size: 16px;
      line-height: 26px; } }

.fz1628 {
  font-size: 16px;
  line-height: 28px; }
  @media (max-width: 575.8px) {
    .fz1628 {
      font-size: 14px;
      line-height: 28px; } }
  .fz1628 p,
  .fz1628 a {
    font-size: 16px;
    line-height: 28px; }
    @media (max-width: 575.8px) {
      .fz1628 p,
      .fz1628 a {
        font-size: 14px;
        line-height: 28px; } }

.fz1426 {
  font-size: 14px;
  line-height: 26px; }

.fw600 {
  font-weight: 600; }

.fw799 {
  font-weight: 700; }

.c57 {
  color: #575757; }

.btn-orange {
  color: var(--accent-color);
  cursor: pointer; }

.ml10 {
  margin-left: 10px; }

.mr10 {
  margin-right: 10px; }

.ml20 {
  margin-left: 20px; }
  @media (max-width: 575.8px) {
    .ml20 {
      margin-left: 15px; } }

.mr20 {
  margin-right: 20px; }
  @media (max-width: 575.8px) {
    .mr20 {
      margin-right: 15px; } }

.ml30 {
  margin-left: 30px; }
  @media (max-width: 575.8px) {
    .ml30 {
      margin-left: 20px; } }

.mr30 {
  margin-right: 30px; }
  @media (max-width: 575.8px) {
    .mr30 {
      margin-right: 20px; } }

.mt30 {
  margin-top: 30px; }
  @media (max-width: 575.8px) {
    .mt30 {
      margin-top: 15px; } }

.mb30 {
  margin-bottom: 30px; }
  @media (max-width: 575.8px) {
    .mb30 {
      margin-bottom: 15px; } }

.mt50 {
  margin-top: 50px; }
  @media (max-width: 575.8px) {
    .mt50 {
      margin-top: 20px; } }

.mt50i {
  margin-top: 50px !important; }
  @media (max-width: 575.8px) {
    .mt50i {
      margin-top: 20px !important; } }

.mb50 {
  margin-bottom: 50px; }
  @media (max-width: 575.8px) {
    .mb50 {
      margin-bottom: 20px; } }

.mt20 {
  margin-top: 20px; }
  @media (max-width: 575.8px) {
    .mt20 {
      margin-top: 15px; } }

.mb20 {
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .mb20 {
      margin-bottom: 15px; } }

.mb5 {
  margin-bottom: 5px !important; }

.mt5 {
  margin-top: 5px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt0 {
  margin-top: 0 !important;
  flex-grow: 1; }

.mt-15 {
  margin-top: -15px !important; }

.w100 {
  width: 100% !important; }

.pt20 {
  padding-top: 20px; }
  @media (max-width: 575.8px) {
    .pt20 {
      padding-top: 15px; } }

.pb20 {
  padding-bottom: 20px; }
  @media (max-width: 575.8px) {
    .pb20 {
      padding-bottom: 15px; } }

.p0 {
  padding: 0 !important; }

.m_auto {
  margin-right: auto;
  margin-left: auto; }

.pt50 {
  padding-top: 50px; }
  @media (max-width: 575.8px) {
    .pt50 {
      padding-top: 20px; } }

.pt30 {
  padding-top: 30px; }
  @media (max-width: 575.8px) {
    .pt30 {
      padding-top: 15px; } }

.pb100 {
  padding-bottom: 100px; }
  @media (max-width: 575.8px) {
    .pb100 {
      padding-bottom: 50px; } }

.pb50 {
  padding-bottom: 50px; }
  @media (max-width: 575.8px) {
    .pb50 {
      padding-bottom: 20px; } }

.pb30 {
  padding-bottom: 30px; }
  @media (max-width: 575.8px) {
    .pb30 {
      padding-bottom: 15px; } }

.title-invent {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 35px;
  color: #575757;
  margin-bottom: 20px; }
  @media (max-width: 575.8px) {
    .title-invent {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 17px; } }

.description-invent {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #8c8c8c;
  line-height: 28px; }
  .description-invent a {
    color: var(--accent-color) !important; }
  @media (max-width: 575.8px) {
    .description-invent {
      font-size: 14px;
      line-height: 28px; } }

.caption {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  color: #575757;
  z-index: 5; }
  .caption b {
    display: flex;
    margin-top: -5px; }
    .caption b span {
      right: 4px !important; }
  @media (max-width: 575.8px) {
    .caption {
      font-size: 22px;
      line-height: 30px; } }

.wrap-loader-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background: rgba(255, 255, 255, 0.8); }

.wrap-loader {
  margin: 20px 0;
  text-align: center;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; }
  @media (max-width: 767.8px) {
    .wrap-loader svg {
      transform: scale(0.7); } }

.btn-green {
  background: #4caf50;
  border-radius: 2px;
  width: 262px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  transition: background 0.34s;
  font-size: 14px;
  text-transform: uppercase; }
  @media (max-width: 575.8px) {
    .btn-green {
      border-radius: 2px;
      width: 100%;
      height: 31px;
      font-weight: 600;
      font-size: 10px;
      max-width: 256px;
      line-height: 26px; } }

.btn-green:hover {
  background: rgba(76, 175, 80, 0.7); }

.btn-green:active {
  background: rgba(76, 175, 80, 0.5); }

.button-orange {
  background: var(--accent-color);
  border-radius: 2px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  max-width: 500px;
  height: 40px;
  transform: scale(1);
  transition: transform 0.25s ease-in-out; }
  .button-orange:hover {
    transform: scale(1.05); }
  .button-orange:disabled {
    background: #8c8c8c;
    cursor: not-allowed; }

.button-orange-light {
  width: 100%;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light-accent-color) !important;
  border-radius: 2px;
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  line-height: 26px;
  color: var(--accent-color) !important;
  transition: color 0.35s ease, background 0.35s ease !important;
  height: 40px;
  margin: 0 auto;
  padding: 0 20px; }
  .button-orange-light:disabled {
    background: #8c8c8c;
    cursor: not-allowed;
    color: #fff; }

.fz1728 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 28px; }

.c8c {
  color: #8c8c8c; }

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.relative {
  position: relative; }

.scroll {
  overflow-y: scroll !important; }
  .scroll::-webkit-scrollbar {
    width: 2px; }
  .scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
  .scroll::-webkit-scrollbar-thumb {
    background-color: rgba(229, 102, 59, 0.61);
    outline: 1px solid slategrey; }

.ticketIcon {
  position: relative;
  display: inline-flex; }
  .ticketIcon strong {
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    top: 50%;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #ffffff; }

.textOnButton {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }
  .textOnButton span {
    color: var(--accent-color);
    margin-right: 5px;
    font-style: normal;
    font-weight: 600;
    position: relative;
    top: -2px; }

.modalType {
  background: var(--background-color) !important;
  max-width: 962px; }

.CloseBtn {
  top: 0;
  right: 0;
  position: absolute;
  opacity: 0.9;
  display: inline-flex;
  transition: 0.2s;
  transform: translate(50%, -50%);
  z-index: 20; }
  .CloseBtn svg {
    opacity: 1 !important; }
  .CloseBtn:after {
    content: '';
    width: 15px;
    height: 15px;
    background: #fff;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    display: block;
    position: absolute;
    z-index: -1;
    border-radius: 10em; }
  .CloseBtn:hover {
    opacity: 1; }
  @media (max-width: 576px) {
    .CloseBtn {
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: #333; } }

b {
  font-style: italic;
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  color: #8c8c8c; }

.inputWithCalendar {
  position: relative; }
  .inputWithCalendar input {
    padding-right: 20px; }
  .inputWithCalendar svg,
  .inputWithCalendar span {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #8c8c8c;
    font-weight: 700; }
    .inputWithCalendar svg path,
    .inputWithCalendar span path {
      fill: #8c8c8c; }

.text-orange {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  color: var(--accent-color); }

.color-orange {
  color: var(--accent-color) !important; }

.overlay-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10; }

.dashboard {
  background: var(--background-color);
  min-height: 100vh; }

.event-lists {
  padding-bottom: 100px; }
  @media (max-width: 1023.8px) {
    .event-lists {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center; } }

.event-list {
  display: flex;
  position: relative;
  margin-bottom: 30px;
  background: #fff;
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.09); }
  .event-list:last-child {
    margin-bottom: 0; }
  @media (max-width: 1023.8px) {
    .event-list {
      flex-direction: column;
      width: 500px;
      flex-shrink: 0; } }
  @media (max-width: 575.8px) {
    .event-list {
      width: 100%; } }

.wrap-circle {
  display: flex;
  align-items: center;
  justify-content: center; }
  .wrap-circle span {
    position: relative; }
  .wrap-circle small {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    color: #8c8c8c; }

.unlimited {
  font-size: 22px !important; }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(140, 140, 140, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.35s ease; }
  .overlay svg {
    transform: rotate(0);
    transition: transform 0.5s ease; }

.VOLUNTEER {
  opacity: 0 !important; }

.event-img {
  width: 420px;
  min-height: 266px;
  height: 100%;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
  transform: scale(1);
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 1023.8px) {
    .event-img {
      min-height: 264px;
      width: 100%; } }
  .event-img:hover .overlay {
    opacity: 1;
    transform: scale(1.2); }
    .event-img:hover .overlay svg {
      transform: rotate(180deg); }

.show-more {
  right: 20px;
  position: absolute;
  top: 20px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10; }
  @media (max-width: 1023.8px) {
    .show-more {
      top: 272px;
      right: 11px; }
      .show-more svg circle {
        fill: #575757 !important; } }

.setting-event {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  min-width: 312px;
  transition: transform 0.35s ease, opacity 0.35s ease;
  padding: 30px 25px;
  background: #fff;
  box-shadow: 0 28px 16px rgba(11, 12, 36, 0.03);
  transform: translateX(100%);
  opacity: 0;
  z-index: 1; }
  @media (max-width: 1023.8px) {
    .setting-event {
      bottom: auto;
      min-width: 100%; } }
  .setting-event.show {
    transform: translateX(0);
    opacity: 1; }
  .setting-event a,
  .setting-event button {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: var(--accent-color);
    display: inline-flex;
    margin-bottom: 15px;
    transition: text-indent 0.35s ease;
    text-indent: 0;
    margin-left: 0;
    padding-left: 0; }
    .setting-event a:hover,
    .setting-event button:hover {
      text-indent: 10px; }
    .setting-event a:last-child,
    .setting-event button:last-child {
      margin-bottom: 0; }

.role {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--accent-color);
  z-index: 10;
  padding: 5px 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px; }

.event-description {
  flex-grow: 1;
  display: flex;
  padding: 0 30px;
  flex-direction: column;
  z-index: 1; }
  @media (max-width: 1023.8px) {
    .event-description {
      padding: 0 20px; } }
  @media (max-width: 575.8px) {
    .event-description {
      padding: 0 10px; } }

.event-top {
  display: flex;
  justify-content: space-between;
  padding: 20px 0; }
  .event-top h3 {
    margin: 15px 0;
    cursor: pointer; }
    .event-top h3 svg {
      margin-left: 10px;
      opacity: 0; }
    .event-top h3:hover svg {
      opacity: 1; }
  @media (max-width: 1023.8px) {
    .event-top span,
    .event-top p {
      font-size: 16px; } }
  @media (max-width: 575.8px) {
    .event-top span,
    .event-top p {
      font-size: 14px;
      line-height: 18px; }
    .event-top h3 {
      font-size: 20px;
      margin: 10px 0; } }
  @media (max-width: 1023.8px) {
    .event-top > div:first-child {
      padding-right: 20px; } }
  .event-top > div:last-child {
    margin-right: 50px;
    margin-left: 20px; }
    @media (max-width: 1023.8px) {
      .event-top > div:last-child {
        margin-right: 20px; }
        .event-top > div:last-child svg {
          width: 70px;
          height: 70px; } }
    @media (max-width: 575.8px) {
      .event-top > div:last-child {
        margin-left: 0;
        margin-right: 0;
        padding-right: 46px; } }

.event-bottom {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px dashed rgba(140, 140, 140, 0.3); }
  @media (max-width: 1023.8px) {
    .event-bottom p {
      font-size: 14px; } }
  @media (max-width: 575.8px) {
    .event-bottom {
      flex-direction: column; }
      .event-bottom > div:first-child {
        margin-bottom: 20px; } }
  .event-bottom > div:first-child {
    display: flex; }
    .event-bottom > div:first-child p {
      display: flex;
      flex-direction: column;
      margin-left: 15px; }
      .event-bottom > div:first-child p small {
        display: inline-block;
        color: #8c8c8c;
        line-height: 28px; }
  .event-bottom > div:last-child p {
    color: #8c8c8c !important; }
  .event-bottom > div:last-child .accent {
    color: #4caf50; }
  @media (max-width: 575.8px) {
    .event-bottom > div:last-child {
      margin-top: 10px; } }

.without-organizers > div:first-child {
  opacity: 0;
  visibility: hidden; }

.contact {
  margin-left: 90px !important; }

.avatar {
  display: flex;
  width: 62px;
  height: 62px;
  border-radius: 10em;
  overflow: hidden;
  border: 1px solid #fff;
  background: #8c8c8c;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  background-size: cover;
  background-position: center;
  position: absolute; }
  .avatar svg path {
    fill: #fff !important; }
  .avatar img {
    min-width: 100%; }
  @media (max-width: 1023.8px) {
    .avatar {
      width: 50px;
      height: 50px; } }

.name-event {
  display: block;
  margin: 15px 0; }

.user {
  position: relative; }
