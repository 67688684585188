.wrap-crumbs {
  padding: 30px 0;
}

.crumbs {

  a {
    font-family: var(--font-family-title);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #8C8C8C;
    transition: color .35s ease;
    &:hover{
      color: var(--accent-color);
    }

  }
  span{
    display: inline-flex;
    margin: 0 10px;
  }
}
