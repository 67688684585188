.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  padding: 30px;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  -ms-overflow-style: none;
  overflow: auto;

  &-enter {
    opacity: 0.01;

    .Modal {
      transform: translateY(50px);
      opacity: 0;
    }
  }

  &-enter-active {
    opacity: 1;
    transition: 300ms ease-in;

    .Modal {
      transition-delay: 100ms;
      transform: translateY(50px);
      opacity: 0;
    }
  }

  &-exit {
    opacity: 1;

  }

  &-exit-active {
    opacity: 0.01;
    transition: 300ms ease-out;

  }

  @media (max-width: 576px) {
    padding: 0px;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.Modal {
  position: relative;
  width: 90%;
  max-width: 700px;
  margin: auto;
  height: auto;
  background: #fff;
  padding: 20px 40px 40px;
  border-radius: 2px;
  transition: all 300ms ease-in-out;

  strong {
    color: var(--accent-color);
    font-weight: 600;
  }

  a {
    font-family: var(--font-family);
    font-style: italic;
    font-size: 16px;
    line-height: 21px;
    color: var(--accent-color);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 576px) {
    padding-top: 20px;
    max-width: 100%;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

