@import '~scss/variables.scss';

.Transition {
	&-enter {
		opacity: 0.01;
	}

	&-enter-active {
		opacity: 1;
		transition: 300ms ease-in;
	}

	&-exit {
		opacity: 1;
	}

	&-exit-active {
		opacity: 0.01;
		transition: 300ms ease-out;
	}
}

.italic {
	font-style: italic;
	font-weight: 300;
	font-size: 16px;
	line-height: 21px;
}

.timePickerMaxIndex {
	z-index: 999999 !important;
}

@keyframes pulse_animation {
	0% {
		transform: scale(1);
	}
	30% {
		transform: scale(1);
	}
	40% {
		transform: scale(1.08);
	}
	50% {
		transform: scale(1);
	}
	60% {
		transform: scale(1);
	}
	70% {
		transform: scale(1.05);
	}
	80% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}

@-webkit-keyframes pulse-shadow {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
	}
}

@keyframes pulse-shadow {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
		box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
		box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
		box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
	}
}

.pulse-icon {
	display: block;
	border-radius: 10em;
	cursor: pointer;
	animation: pulse-shadow 2s infinite;

	&:hover {
		animation: none;
	}
}

.warning {
	display: block;
	padding: 10px 20px;
	font-size: 18px;
	line-height: 22px;
	font-weight: 300;
	position: relative;
	background: var(--accent-color);
	color: #4b4b4b;
	border-radius: 2px;
	margin: 10px 0;

	p {
		color: #fff;
	}

	&.error {
		background: rgba(#fc4458, 0.5) !important;
	}
}

.pulse {
	span,
	path {
		animation-name: pulse_animation;
		animation-duration: 2000ms;
		transform-origin: center;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
}

.custom-btn {
	overflow: hidden;
	position: relative;
}

.custom-btn:before {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: #fff;
	opacity: 0;
	z-index: 1;
	-webkit-transition: ease 0.2s;
	transition: ease 0.2s;
}

.custom-btn:after {
	content: '';
	display: block;
	width: 30px;
	height: 300px;
	margin-left: 60px;
	background: #fff;
	position: absolute;
	left: -40px;
	top: -150px;
	z-index: 1;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.1s;
	transition: all 0.1s;
	-webkit-animation-name: slideme;
	animation-name: slideme;
	-webkit-animation-duration: 2.5s;
	animation-duration: 2.5s;
	-webkit-animation-delay: 0.05s;
	animation-delay: 0.05s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

@-webkit-keyframes slideme {
	0% {
		left: -30px;
		margin-left: 0px;
	}
	40% {
		left: 110%;
		margin-left: 80px;
	}
	100% {
		left: 110%;
		margin-left: 80px;
	}
}

.wrap-select-custom {
	width: 100px !important;
	margin: 0;
	margin-bottom: 0 !important;
	flex-shrink: 0;

	input {
		height: 35px !important;
		margin-top: 0;
	}

	span {
		right: 0px;
	}
}

.opacity0 {
	opacity: 0 !important;
}

.titleWithSelect {
	display: flex;
	align-items: center;

	span {
		flex-shrink: 0;
		display: inline-flex;
		margin-right: 20px;
	}
}

.require {
	position: relative;
	right: -4px;
	top: -2px;
	color: var(--accent-color);
}

.container70 {
	padding: 0 70px;
	@include tablet {
		padding: 0;
	}
}

.color-green {
	color: #4caf50;
}

.justify-center {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.content-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.max969 {
	max-width: 969px;
	margin-left: auto;
	margin-right: auto;
}

.fz2550 {
	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	line-height: 50px;
	text-align: center;
}

.fz2530 {
	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	line-height: 30px;
	text-align: center;
}

.text-left {
	text-align: left !important;
}

.fz1830 {
	font-size: 18px;
	line-height: 30px;
	@include phone {
		font-size: 16px;
		line-height: 26px;
	}
}

.fz1628 {
	font-size: 16px;
	line-height: 28px;
	@include phone {
		font-size: 14px;
		line-height: 28px;
	}

	p,
	a {
		font-size: 16px;
		line-height: 28px;
		@include phone {
			font-size: 14px;
			line-height: 28px;
		}
	}
}

.fz1426 {
	font-size: 14px;
	line-height: 26px;
}

.fw600 {
	font-weight: 600;
}

.fw799 {
	font-weight: 700;
}

.c57 {
	color: #575757;
}

.btn-orange {
	color: $color-primary;
	cursor: pointer;
}

.ml10 {
	margin-left: 10px;
}

.mr10 {
	margin-right: 10px;
}

.ml20 {
	margin-left: 20px;
	@include phone {
		margin-left: 15px;
	}
}

.mr20 {
	margin-right: 20px;
	@include phone {
		margin-right: 15px;
	}
}

.ml30 {
	margin-left: 30px;
	@include phone {
		margin-left: 20px;
	}
}

.mr30 {
	margin-right: 30px;
	@include phone {
		margin-right: 20px;
	}
}

.mt30 {
	margin-top: 30px;
	@include phone {
		margin-top: 15px;
	}
}

.mb30 {
	margin-bottom: 30px;
	@include phone {
		margin-bottom: 15px;
	}
}

.mt50 {
	margin-top: 50px;
	@include phone {
		margin-top: 20px;
	}
}

.mt50i {
	margin-top: 50px !important;
	@include phone {
		margin-top: 20px !important;
	}
}

.mb50 {
	margin-bottom: 50px;
	@include phone {
		margin-bottom: 20px;
	}
}

.mt20 {
	margin-top: 20px;
	@include phone {
		margin-top: 15px;
	}
}

.mb20 {
	margin-bottom: 20px;
	@include phone {
		margin-bottom: 15px;
	}
}

.mb5 {
	margin-bottom: 5px !important;
}

.mt5 {
	margin-top: 5px !important;
}

.mb0 {
	margin-bottom: 0 !important;
}

.mt0 {
	margin-top: 0 !important;
	flex-grow: 1;
}

.mt-15 {
	margin-top: -15px !important;
}

.w100 {
	width: 100% !important;
}

.pt20 {
	padding-top: 20px;
	@include phone {
		padding-top: 15px;
	}
}

.pb20 {
	padding-bottom: 20px;
	@include phone {
		padding-bottom: 15px;
	}
}

.p0 {
	padding: 0 !important;
}

.m_auto {
	margin-right: auto;
	margin-left: auto;
}

.pt50 {
	padding-top: 50px;
	@include phone {
		padding-top: 20px;
	}
}

.pt30 {
	padding-top: 30px;
	@include phone {
		padding-top: 15px;
	}
}

.pb100 {
	padding-bottom: 100px;
	@include phone {
		padding-bottom: 50px;
	}
}

.pb50 {
	padding-bottom: 50px;
	@include phone {
		padding-bottom: 20px;
	}
}

.pb30 {
	padding-bottom: 30px;
	@include phone {
		padding-bottom: 15px;
	}
}

.title-invent {
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 35px;
	color: $color-black;
	margin-bottom: 20px;
	@include phone {
		font-size: 19px;
		line-height: 30px;
		margin-bottom: 17px;
	}
}

.description-invent {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: $let-color-grey;
	line-height: 28px;

	a {
		color: var(--accent-color) !important;
	}

	@include phone {
		font-size: 14px;
		line-height: 28px;
	}
}

.description-invent :hover {
}

.caption {
	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	line-height: 50px;
	text-align: center;
	color: #575757;
	z-index: 5;

	b {
		display: flex;
		margin-top: -5px;

		span {
			right: 4px !important;
		}
	}

	@include phone {
		font-size: 22px;
		line-height: 30px;
	}
}

.wrap-loader-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	background: rgba(#fff, 0.8);
}

.wrap-loader {
	margin: 20px 0;
	text-align: center;
	min-height: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	@include landscape {
		svg {
			transform: scale(0.7);
		}
	}
}

.btn-green {
	background: $let-color-green;
	border-radius: 2px;
	width: 262px;
	height: 40px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-style: normal;
	font-weight: 600;
	transition: background 0.34s;
	font-size: 14px;
	text-transform: uppercase;
	@include phone {
		border-radius: 2px;
		width: 100%;
		height: 31px;
		font-weight: 600;
		font-size: 10px;
		max-width: 256px;
		line-height: 26px;
	}
}

.btn-green:hover {
	background: $let-color-green-hover;
}

.btn-green:active {
	background: $let-color-green-pressed;
}

.button-orange {
	background: var(--accent-color);
	border-radius: 2px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 26px;
	text-transform: uppercase;
	width: 100%;
	max-width: 500px;
	height: 40px;
	transform: scale(1);
	transition: transform 0.25s ease-in-out;

	&:hover {
		transform: scale(1.05);
	}

	&:disabled {
		background: #8c8c8c;
		cursor: not-allowed;
	}
}

.button-orange-light {
	width: 100%;
	max-width: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--light-accent-color) !important;
	border-radius: 2px;
	font-style: normal;
	font-weight: bold !important;
	font-size: 16px;
	line-height: 26px;
	color: var(--accent-color) !important;
	transition:
		color 0.35s ease,
		background 0.35s ease !important;
	height: 40px;
	margin: 0 auto;
	padding: 0 20px;

	&:disabled {
		background: #8c8c8c;
		cursor: not-allowed;
		color: #fff;
	}
}

.fz1728 {
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 28px;
}

.c8c {
	color: #8c8c8c;
}

.space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.relative {
	position: relative;
}

.scroll {
	overflow-y: scroll !important;

	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(229, 102, 59, 0.61);
		outline: 1px solid slategrey;
	}
}

.ticketIcon {
	position: relative;
	display: inline-flex;

	strong {
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		top: 50%;
		position: absolute;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		color: #ffffff;
	}
}

.textOnButton {
	font-style: italic;
	font-weight: 300;
	font-size: 17px;
	line-height: 28px;
	color: #8c8c8c;

	span {
		color: var(--accent-color);
		margin-right: 5px;
		font-style: normal;
		font-weight: 600;
		position: relative;
		top: -2px;
	}
}

.modalType {
	background: var(--background-color) !important;
	max-width: 962px;
}

.CloseBtn {
	top: 0;
	right: 0;
	position: absolute;
	opacity: 0.9;
	display: inline-flex;
	transition: 0.2s;
	transform: translate(50%, -50%);
	z-index: 20;

	svg {
		opacity: 1 !important;
	}

	&:after {
		content: '';
		width: 15px;
		height: 15px;
		background: #fff;
		top: 50%;
		transform: translate(-50%, -50%);
		left: 50%;
		display: block;
		position: absolute;
		z-index: -1;
		border-radius: 10em;
	}

	&:hover {
		opacity: 1;
	}

	@media (max-width: 576px) {
		top: 20px;
		right: 20px;
		font-size: 30px;
		color: #333;
	}
}

b {
	font-style: italic;
	font-weight: 300;
	font-size: 17px;
	line-height: 28px;
	color: #8c8c8c;
}

.inputWithCalendar {
	position: relative;

	input {
		padding-right: 20px;
	}

	svg,
	span {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		color: #8c8c8c;
		font-weight: 700;

		path {
			fill: #8c8c8c;
		}
	}
}

.text-orange {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 26px;
	text-transform: uppercase;
	color: var(--accent-color);
}

.color-orange {
	color: var(--accent-color) !important;
}

.overlay-loader {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(#fff, 0.9);
	z-index: 10;
}
