/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.banner {
  margin: 14px auto 15px;
  overflow: hidden;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 665px;
  height: 350px; }
  .banner:after {
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    left: 0;
    transform: translateX(-50%);
    background-color: var(--background-color);
    border-radius: 50%;
    content: ''; }
  .banner:before {
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    right: 0;
    transform: translateX(50%);
    background-color: var(--background-color);
    border-radius: 50%;
    content: ''; }
  .banner img {
    width: 665px; }
  @media (max-width: 1023.8px) {
    .banner {
      max-width: 665px;
      width: 100%; } }
  @media (max-width: 575.8px) {
    .banner {
      width: calc(100% + 32px);
      position: relative;
      left: -16px;
      margin-top: 0;
      background-image: none !important;
      height: auto !important; }
      .banner img {
        display: block;
        width: 100%; }
      .banner:after, .banner:before {
        content: none; } }
