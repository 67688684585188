/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Container {
  position: relative; }
  .Container button {
    font-style: normal;
    padding: 0 !important;
    margin: 0 !important;
    font-weight: normal;
    position: relative;
    font-size: 16px;
    line-height: 28px;
    color: #575757;
    display: flex;
    align-items: center;
    justify-content: center !important;
    width: 100% !important;
    padding-left: 15px !important; }
    .Container button:last-child {
      margin-bottom: 0; }
  .Container svg {
    position: absolute;
    left: 0;
    top: calc(50% - 4px);
    margin-right: 7px;
    margin-bottom: 1px;
    height: 8px;
    transition: 0.2s;
    transform: rotate(90deg); }

.Open svg {
  margin-right: 5px;
  height: 8px;
  transform: rotate(270deg); }

.Open .List {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px); }

.List {
  position: absolute;
  top: 33px;
  right: 0;
  overflow: hidden;
  transition: opacity .32s ease, transform .32s ease;
  width: 130px;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  opacity: 0;
  background: #fff;
  transform: translateY(20px);
  visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2px; }
  @media (max-width: 1199.8px) {
    .List {
      right: auto;
      left: 0; } }
  .List li {
    width: 100%;
    transition: background .35s ease;
    padding: 4px 15px; }
    .List li button {
      padding-left: 0 !important; }
    .List li:hover {
      background: #fff2de; }
    .List li:active {
      background: rgba(255, 242, 222, 0.7); }
  .List li:not(:last-child) {
    margin-bottom: 5px; }

.ml {
  margin-right: 0;
  margin-left: 70px; }
