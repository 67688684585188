@keyframes Close {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  25% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes Progress {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.Notification {
  width: 300px;
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 10px 0 rgba(0,0,0,.1), 0 2px 15px 0 rgba(0,0,0,.05);
  color: #ffffff;
  font-size: 16px;

  button, a {
    color: #ffffff;
    text-decoration: underline;
    padding: 0;
  }

  &_info {
    background: #3498db;
  }
  &_success {
    background: #07bc0c;
  }
  &_warning {
    background: #f1c40f;
  }
  &_error {
    background: #e74c3c;
  }

  &_default {
    color: #222!important;
    background: #ffffff;

    .NotificationClose {
      color: #222;
    }
  }

  &_close {
    animation: Close 1s linear;
  }

  &Close {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    color: #ffffff;
  }

  &Progress {
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    animation-name: Progress;
    animation-timing-function: linear;
    animation-play-state: paused;
    background: rgba(#fff, .6);
  }
}