/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  padding: 30px;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  -ms-overflow-style: none;
  overflow: auto;
  transition: all 0.3s ease;
  transform: scale(2);
  visibility: hidden;
  opacity: 0;
  /* ONLY FOR MODAL SIGN*/ }
  .overlay-modal.show {
    transform: scale(1);
    opacity: 1;
    visibility: visible; }
  @media (max-width: 576px) {
    .overlay-modal {
      padding: 0px; } }
  .overlay-modal::-webkit-scrollbar {
    width: 0; }
  .overlay-modal .modal {
    position: relative;
    width: 90%;
    max-width: 830px;
    margin: auto;
    height: auto;
    background: var(--background-color);
    border-radius: 2px;
    padding: 30px 60px; }
    @media (max-width: 568px) {
      .overlay-modal .modal {
        padding: 20px; } }
    .overlay-modal .modal.withImage {
      max-width: 1170px; }
    .overlay-modal .modal .btn-blue,
    .overlay-modal .modal .btn-grey {
      display: flex;
      align-items: center;
      margin: 0 auto; }
    .overlay-modal .modal h3 {
      text-align: center;
      padding: 20px;
      font-size: 22px; }
    .overlay-modal .modal .modal-footer {
      border-top: 1px solid rgba(0, 0, 0, 0.2); }
    .overlay-modal .modal .modal-header {
      font-weight: 500;
      padding: 27px 0;
      text-align: center;
      font-size: 25px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      color: #000; }
    .overlay-modal .modal .modal-main {
      text-align: left; }
    .overlay-modal .modal .close {
      top: 0;
      right: 0;
      position: absolute;
      opacity: 0.8;
      z-index: 100;
      color: #fff;
      transition: 0.2s;
      transform: translate(50%, -50%); }
      @media (max-width: 578px) {
        .overlay-modal .modal .close {
          transform: translate(0, 0); } }
      .overlay-modal .modal .close:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10em;
        width: 13px;
        height: 13px;
        background: #fff;
        z-index: -1; }
      .overlay-modal .modal .close:hover {
        opacity: 1; }
    @media (max-width: 576px) {
      .overlay-modal .modal {
        width: 100%;
        padding-top: 20px;
        max-width: 100%;
        height: 100%;
        border-radius: 0;
        display: flex;
        overflow: auto;
        flex-direction: column;
        justify-content: space-between; }
        .overlay-modal .modal .close {
          top: 0;
          right: 0px;
          font-size: 30px;
          color: #333; }
        .overlay-modal .modal .modal-header {
          padding: 15px 0; }
        .overlay-modal .modal .modal-main {
          padding: 20px; } }
    @media (max-width: 576px) and (max-width: 578px) {
      .overlay-modal .modal .modal-main {
        padding: 0; } }
    @media (max-width: 576px) {
        .overlay-modal .modal .modal .btn-blue {
          line-height: 43px;
          height: 40px; } }
  .overlay-modal .modal .modal-footer {
    text-align: center;
    border: none; }
  .overlay-modal .modal p {
    font-size: 16px; }
  .overlay-modal .modal .login-row {
    display: flex; }
  .overlay-modal .modal .checkbox-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .overlay-modal .modal .checkbox-field label {
      margin-left: 15px; }
  .overlay-modal .modal label svg path {
    fill: #8c8c8c;
    transition: fill 0.35s ease; }
  .overlay-modal .modal label div {
    position: relative; }
  .overlay-modal .modal label .password-field {
    position: relative; }
    .overlay-modal .modal label .password-field input {
      padding-right: 40px; }
    .overlay-modal .modal label .password-field.is-show svg path {
      fill: var(--accent-color); }
    .overlay-modal .modal label .password-field strong[role] {
      position: absolute;
      top: 50%;
      display: inline-flex;
      transform: translateY(calc(-50% + 1px));
      right: 10px; }
  .overlay-modal .modal .passwordForm {
    color: rgba(0, 0, 0, 0.8); }
    .overlay-modal .modal .passwordForm h5 {
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      margin: 20px 0; }
    .overlay-modal .modal .passwordForm label {
      text-align: left;
      display: block;
      margin-bottom: 20px; }
  .overlay-modal .modal .social {
    width: 32px;
    height: 32px;
    display: inline-block;
    border-radius: 50%;
    background-color: #4e71a8;
    line-height: 36px;
    font-size: 16px;
    color: #fff;
    margin-left: 20px; }
    .overlay-modal .modal .social.gp {
      background-color: #e3411f; }
    .overlay-modal .modal .social:hover {
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.9); }

.forgot {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-align: right;
  color: var(--accent-color);
  cursor: pointer; }

.wrap-remember {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 578px) {
    .wrap-remember {
      flex-direction: column;
      align-items: flex-start; }
      .wrap-remember button {
        margin-top: 20px; } }

.maxWidth500 {
  position: relative;
  z-index: 2;
  max-width: 500px; }
  @media (max-width: 992px) {
    .maxWidth500 {
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 578px) {
    .maxWidth500 {
      max-width: 100%;
      width: 100%; } }

.agreeWithTerm {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #8c8c8c; }
  .agreeWithTerm a {
    color: var(--accent-color); }

.agreeWithTerm {
  display: flex; }
  .agreeWithTerm label {
    margin-left: 20px;
    margin-top: -5px; }

.widthAuto {
  width: auto !important; }
