.imageForm {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  left: 0;
  top: 0;
  overflow: hidden;

  > * {
    will-change: transform;

    position: absolute;
  }
}

.big-box {
  right: 200px;
  z-index: 1;
  bottom: 90px;
}

.small-box {
  right: 180px;
  z-index: 10;
  bottom: 80px;
}

.box-shadow {
  bottom: 50px;
  right: 180px;
}
.shadow{
  bottom: 140px;
  right: 50px;
  z-index: -1;
}

.ticket {
  bottom: 190px;
  right: 170px;
  z-index: 20;
}

.icon {
  &-1 {
    right: 30px;
    bottom: 400px;

  }

  &-2 {
    right: 110px;
    bottom: 300px;
  }

  &-3 {
    right: 200px;
    bottom: 440px;
    z-index: 8;
  }

  &-4 {
    right: 320px;
    z-index: 22;
    bottom: 380px;
    filter: blur(1px);
  }
  &-5 {
    bottom: 600px;
    right: 200px;
  }
  &-6 {
    bottom: 440px;
  }
}