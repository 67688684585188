@import '~react-phone-input-2/dist/style.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Poppins:wght@400;700&family=Open+Sans:wght@400;500;600;700&display=swap');
@import '~scss/variables.scss';

:root {
	--brand-primary: #f6db83;
	--brand-primary-light: var(--background-color); //#faf8f5;
}

* {
	font-family: var(--font-family);
}

body {
	font-family: var(--font-family);
	color: $color-black;
}

h1 {
	font-family: var(--font-family-title);
}

.container {
	@include container;
	@include phone {
		&.containerInvent {
			padding: 0 16px;
		}
	}
}

input,
select,
textarea,
a,
button {
	font-family: inherit;
}

textarea {
	resize: none;
}

a,
button {
	cursor: pointer;
	transition: 0.2s;
}

h1 {
	font-weight: 700;
	font-size: 30px;
	line-height: 1.66em;
	margin: 0;

	@include landscape {
		font-size: 19px;
	}
}

h2 {
	font-weight: 600;
	font-size: 20px;
	line-height: 1.6em;
	margin: 0;

	@media (max-width: $breakpoint-tablet) {
		font-size: 16px;
	}
}

h3 {
	font-weight: 700;
	font-size: 14px;
	line-height: 1.85em;
	margin: 0;
}

h4 {
	font-weight: 600;
	font-size: 17px;
	line-height: 1.65em;
	margin: 0;
}

p {
	line-height: 1.66em;
	margin: 0;
	font-size: 18px;
	color: $color-black;

	@media (max-width: $breakpoint-tablet) {
		font-size: 14px;
	}
}

p.small {
	line-height: 1.75em;
	font-size: 16px;
}

svg path {
	transition: fill 0.35s ease;
}

.hide {
	position: absolute;
	opacity: 0;
	left: -10000px;
	visibility: hidden;
}

@import '~scss/other.scss';
