.Error {
	background: var(--background-color); //#FAF8F5;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	&Content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
}
