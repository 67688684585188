/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  z-index: 3;
  position: relative; }

.List {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 5px 6px 15px rgba(11, 12, 36, 0.04);
  border-radius: 2px;
  background-color: #fff;
  padding-top: 60px;
  max-height: 160px;
  overflow: auto;
  z-index: 1; }
  .ListItem {
    padding: 0;
    color: #868686;
    padding: 10px 20px;
    font-size: 14px;
    line-height: 1.4em;
    text-align: left;
    width: 100%; }
    .ListItem:hover {
      color: #575757;
      background: var(--background-color); }

.Loader {
  padding: 10px;
  display: block;
  text-align: center; }
