@import "~scss/variables.scss";


.input-wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;

  input::placeholder {
    font-family: var(--font-family);
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    color: #8C8C8C;
  }
}

.ticketBackground {
  position: relative;
  display: inline-flex;
  cursor: pointer;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    z-index: 10;
  }

  svg {
    z-index: 1;
  }

  &Small {
    transform: scale(.7);
    margin-right: -10px;
  }
}

.wrap-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 90px;
  @include ipad {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}

.labelInput {
  font-size: 16px;
  line-height: 28px;
  color: $let-color-grey;
  @include phone {
    font-size: 14px;
    line-height: 25px;
  }
}

.defaultInput {
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  @include phone {
    width: 100%;
    //height: 30px;
  }
}

input[type='checkbox'] ~ label {
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  color: $let-color-grey;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  flex-shrink: 0;
  line-height: 28px;
  align-items: center;
  @include phone {
    font-size: 14px;
    line-height: 28px;
    align-items: flex-start;
  }
}

.checkbox {
  display: none;
}

.checkbox:checked + label:after {
  opacity: 1;
}

.checkbox + label:after {
  content: '\2714';
  opacity: 0;
  position: absolute;
  transform: scale(.7) translateY(calc(-50% - 5px));
  color: #fff;
  left: 4px;
  top: 50%;

  transition: opacity .35s ease;
  @include phone {
    transform: scale(.7) translateX(3px) translateY(2px);
    top: 3px;
  }

}

.checkbox + label:before {
  content: "";
  border: 0.1em solid $let-color-grey;
  border-radius: 0.2em;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding-left: 0;
  padding-bottom: 0;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  flex-shrink: 0;
  transition: .2s;
  @include phone {
    margin-top: 7px;
    margin-right: 15px;
  }
}

.checkbox + label {
  position: relative;
}

.checkbox + label:active:before {
  transform: scale(0);
}

.checkbox:checked + label:before {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff;
}

.checkbox:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

.checkbox:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

input, textarea, select {
  @include phone {
    font-size: 16px;
  }
}

.simpleSelect {
  position: relative;
  margin-bottom: 20px;
}

.simpleInput {
  position: relative;
  margin-bottom: 20px;

  label {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 17px !important;
    line-height: 28px !important;
    color: #8C8C8C !important;

    span {
      color: var(--accent-color);
      position: relative;
      top: -3px;
      left: 2px;
    }
  }

  input {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    background: #FFFFFF;
    font-size: 17px;
    padding: 10px;
    line-height: 28px;

    &:focus, &:active {
      border: 1px solid #8C8C8C !important;
    }
  }

  textarea {
    min-height: 200px;
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    background: #FFFFFF;
    font-size: 17px;
    line-height: 28px;
    border-radius: 2px;
    padding: 10px;

    &:focus, &:active {
      border: 1px solid #8C8C8C !important;
    }
  }

  select {
    box-shadow: 0px 4px 3px rgba(222, 222, 222, 0.09);
    border-radius: 2px;
    border: 1px solid transparent !important;
    height: 46px;
    width: 100%;
    transition: border .35s ease;
    font-size: 17px;
    padding: 10px;
    line-height: 28px;
    position: relative;

    option {
      width: 30px;
    }

    &:focus, &:active {
      border: 1px solid #8C8C8C !important;
    }
  }
}

.simpleInputBorder {
  margin-bottom: 0;

  input {
    border: 1px solid #8C8C8C !important;
  }
}

.hideBorder {
  input {
    border: 1px solid transparent !important;
    transition: border .35s ease;
  }
}

.borderNone {
  border: 1px solid transparent !important;

  * {
    border: none;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.fz17 {
  font-size: 17px !important;

  * {
    font-size: 17px !important;

  }
}

.radio-control {
  display: flex;
  color: #8C8C8C;
  font-style: normal;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  @include landscape {
    flex-direction: column;

  }

  label {
    display: flex;
    width: 50%;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    @include landscape {
      width: 100%;
    }
  }

  input[type=radio] {
    position: absolute;
    left: -1000px;
    opacity: 0;
    visibility: hidden;
  }
}

.radio {
  width: 17px;
  height: 17px;
  border-radius: 10em;
  border: 1.2px solid #8C8C8C;
  transition: border .35s ease, color .35s ease;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;


  span {
    background: var(--accent-color);
    width: 7px;
    height: 7px;
    transform: scale(0);
    display: block;
    opacity: 0;
    border-radius: 10em;
    transition: opacity .35s ease, transform .35s ease;

  }
}

.active {
  color: var(--accent-color);

  .radio {
    border: 1.2px solid var(--accent-color);

    span {
      transform: scale(1.1);
      opacity: 1;
    }
  }
}

.input_outline {
  position: absolute;
  bottom: 0;
  width: 97%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;

  span {
    position: relative;
    display: block;
    height: 1px;
    width: 100%;
    background: #8C8C8C;
  }
}

input[type='text'], input[type='password'] {
  transition: border .34s ease;

  &:focus, &:active {
    border: 1px solid #8C8C8C !important;
  }
}

.zIndex10 {
  z-index: 10 !important;
}
