$big-size: 24px;


.checkbox-component {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  height: $big-size;
  min-width: $big-size;
  user-select: none;
  
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    height: 100%;
    margin: 0;
    width: 100%;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $big-size;
    width: $big-size;
    background-color: #fff;
    border: 1px solid #cccccc;
    border-radius: 2px;

    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 2px;
      width: 9px;
      height: 14px;
      border: solid #00A5FF;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  
  input:checked ~ .checkmark {
    &::after {
      display: block;
    }
  }
}
.custom{
  min-height: 12px!important;
  width: auto!important;
  height: 100%!important;
  min-width: 12px!important;
  display: flex!important;
  align-items: center!important;
  .checkmark{
    min-height: 12px!important;
    width: 12px!important;
    height: 12px!important;
    min-width: 12px!important;
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);

    border: 1px solid #8C8C8C!important;
    background: transparent;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    &.large{
      transform: scale(1.1) translateY(-50%);
      min-width: 17px!important;
      min-height: 17px!important;

    }
    &.circle{
      border-radius: 10em;
      svg{
        margin-top: 0!important;
      }
    }
    svg{
      transition: all .15s ease-in;
      opacity: 0;
      transform: scale(0);
      margin-top: 1px;
    }
    &:after {
      display: none;
    }

  }
  input:checked ~ .checkmark {
   &::after{
     display: none;
   }
    svg {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.checkbox-component.custom.large {
  min-height: 18px!important;
  min-width: 18px!important;
  input{
    height: 18px;
  }

}