.main {
  background: #FFFFFF;
  box-shadow: 4px 4px 16px rgba(11, 12, 36, 0.03);
  border-radius: 2px;
  padding: 20px 30px;
  position: relative;
  min-height: 100px;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 10;
  margin-top: 15px;
  flex-direction: column;

  p {
    padding: 10px 0 20px;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #8C8C8C;
  }
}

.Content {
  padding: 20px 0 !important;
}
