/*
  GLOBAL VARIABLES
  Import this file to any scss files for use variables
  Please, check variables before adding new
  If you rename a variable - rename it everywhere
*/
/* Branding colors */
/**********************************************
              SCREEN VARIABLES
**********************************************/
.Component {
  box-shadow: 0px 4px 3px rgba(142, 142, 142, 0.09);
  position: relative;
  z-index: 6;
  align-items: center;
  display: flex; }

.Input {
  padding: 18px 6px 13px;
  flex-grow: 1;
  background: #FFFFFF;
  border-radius: 2px;
  font-size: 16px;
  line-height: 1.7em;
  margin: 0;
  border: 1px solid transparent;
  height: 60px;
  color: #575757; }

.Button {
  background: var(--accent-color);
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  border-radius: 0 7px 7px 0;
  height: 100%;
  transition: transform .25s ease-in-out;
  transform: scale(1); }
  .Button:hover {
    transform: scale(1.05); }
  .Button[disabled] {
    cursor: not-allowed; }

.promo-button {
  background: #8c8c8c;
  width: 44px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .promo-button:hover {
    background: rgba(140, 140, 140, 0.7); }
  .promo-button[disabled], .promo-button:active {
    background: rgba(140, 140, 140, 0.5); }

.input-item {
  width: 136px;
  border: 1px solid #8c8c8c;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 12px 20px;
  height: 100%; }
