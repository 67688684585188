.slick-slider{
  width: 350px;
  flex-shrink: 0;
  height: fit-content;
  margin-left: 30px;
  @media (max-width: 1220px) {
    margin-right: 15px;
  }
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 15px);

  }
}

.slick-prev, .slick-next{
  width: 40px;
  height: 40px;
  z-index: 1;
}
.slick-prev:before, .slick-next:before{
  color: var(--accent-color);
  font-size: 28px;
  line-height: 28px;
}
.slick-list{
  border-radius: 2px;
}
