.limit-text{
  font-size: 12px;
  display: flex;
  line-height: 14px;
  strong {
    margin-left: 3px;
  }
}
.event-page {
  .event-after-description-row {
    border-top: 1px solid #ddd;
    padding: 32px 0;
    text-align: center;
  }

  #payment-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .checkbox-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    label {
      margin-left: 15px;
    }
  }

  .checkbox-field_top {
    align-items: flex-start;
    label {
      margin-top: -2px;
    }
  }


  .ribbon {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    text-align: center;
    line-height: 40px;
    letter-spacing: 1px;
    z-index: 2;
    color: #f0f0f0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    &.draft {
      background: #e82;
    }
  }

  .event-cover {
    background: #000;
    display: flex;
    position: relative;
    z-index: 0;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 3%;
  
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.95) 0%, transparent 60%);
    }

    &__img {
      opacity: 1;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__img_shading {
      opacity: 0.4;
    }
  
    &__title {
      padding-top: 106px;
    }
  
    .date-location {
      font-size: 24px;
      color: rgba(255, 255, 255, 0.8);
      font-weight: 500;
      margin-bottom: 12px;
    }
  
    .event-name {
      line-height: 1.15;
      margin-bottom: 60px;
      font-size: 54px;
      color: #fff;
      font-weight: 700;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75); 
    }
  
    &__buying {
      padding: 25px 0;
      border-top: solid 1px rgba(255, 255, 255, 0.1);

      .buying-text,
      .buying-price,
      .buying-btn {
        display: inline-block;
        vertical-align: top; 
      }
  
      .buying-text {
        width: 50%; 
      }
  
      .buying-rigth-side {
        text-align: right;
        display: inline-block;
        vertical-align: top;
        width: 50%; 

        .btn {
          color: #fff;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
  
      .buying-title {
        font-size: 15px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 5px; 
      }
  
      .buying-info {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.2);
        font-weight: 300;
        line-height: 18px;
       }
  
      .buying-price {
        margin-right: 20px; 
        margin-bottom: 24px;
  
        .currency {
          font-size: 34px;
          color: #7c7a9d;
        }
  
        .price {
          font-size: 54px;
          color: #fff;

          &_old {
            text-decoration: line-through;
            font-size: 26px;
            margin-left: 30px;

            .currency {
              font-size: 26px;
            }
          }
        }
      }
  
      &.multiple-tickets {
        position: relative;
        border: none;
        padding-top: 36px;
        
        .blur-bg {
          background-repeat: no-repeat;
          background-position: center 100%;
          background-size: 150% 330%;  
          filter: blur(50px);
          overflow: hidden;
          background: #000;
          width: 200%;
          height: 100%;
          position: absolute;
          left: -50%;
          top: 0;
          z-index: -1;
  
          svg {
            opacity: 0.6;
            position: absolute;
            top: -30%;
            width: 100%;
            height: 220%;
            z-index: -2;
  
            .blur-bg-img {
              height: 300%;
            }
          }
        }
  
        .row {
          display: flex;
          align-items: center;
          padding-top: 25px;
          padding-bottom: 25px;
          border-bottom: solid 1px rgba(#fff, 0.1);
          color: #ffffff;
          font-size: 16px;
  
          &:first-of-type {
            padding-top: 0;
          }
        }
  
        .left-side {
          max-width: 50%;
          flex-basis: 50%;
          padding-right: 5%;
        }
  
        .right-side {
          flex-basis: 50%;
          max-width: 50%;
          text-align: right;
        }
  
        .buying-title {
          font-size: 36px;
          font-weight: 700;
          margin-bottom: 0px;
        }
  
        .buying-info {
          margin-top: 8px;
        }
  
        .currency {
          font-size: 24px;
          color: #7c7a9d;
        }
  
        .price {
          font-size: 40px;
          color: #fff;

          &_old {
            text-decoration: line-through;
            font-size: 26px;
            margin-left: 30px;

            .currency {
              font-size: 26px;
            }
          }
        }
  
        .tickets-left {
          font-size: 11px;
          color: #ccff00;
        }
  
        .ticket-price {
          text-align: right;
          margin-left: 15px;
          min-width: 130px;
          padding-top: 2px;
  
          .tickets-left {
            margin-top: 5px; 
          }
        }
  
        .tickets-number {
          margin-left: 27px;
        }
  
        .expiry-date {
          margin-top: 7px;
          font-size: 12px;
          color: #757c8e;
  
          .date {
            margin-top: 5px;
            color: #fff;
            display: block;
          }
        }
  
        .expiry-date,
        .ticket-price,
        .tickets-number {
          display: inline-block;
          vertical-align: top;
        }
  
        .buying-row {
          padding: 28px 0 20px;
          text-align: right;
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-between;
  
          &.non-active .btn {
            background: #808080;
            cursor: default;
  
            &:before {
              background: #808080;
            }
  
            .btn,
            .btn:before{
              color :#fff;
              background: #007b01;
              cursor: pointer;
            }  


            &:hover span {
              transform: none;
            }
  
            span {
              color: rgba(255, 255, 255, 0.4);
              transition: 0.3s;
            }
  
            .total-price {
              visibility: hidden;
              opacity: 1; 
            }
          }
  
          .total-price {
            display: inline-block;
            vertical-align: top;
            min-width: 130px;
            margin-left: 15px;
            margin-top: 4px;
            visibility: visible;
            opacity: 1;
            transition: 0.3s;
          }
  
          .buying-submit-btn {
            .btn {
              margin-top: 10px;
              margin-left: 0;
              min-width: 242px;
            }
          }
        }
      }
    }
  }

  .event-info {
    padding-top: 56px;

    &__socials {
      float: right;
      width: 50px;
      margin-top: 8px;

      a {
        text-align: center;
        line-height: 50px;
        font-size: 22px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: solid 2px;
        display: block;
        margin-bottom: 18px;
        transition: 0.3s;
        position: relative; 

        svg {
          max-width: 20px;
          max-height: 20px;
          transition: 0.3s;
        }
        
        &:hover {
          box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.28);
        }

        &.twitter {
          border-color: #5EA9DD;
          color: #5EA9DD;
        }

        &.gplus {
          border-color: #DA2713;
          color: #DA2713;
        }

        &.facebook {
          border-color: #3B5998;
          color: #3B5998;
        }
      }
    }


    &__text {
      max-width: 765px;
    /* SHOULD MATCH WITH EVENT`S EDIT DESCRIPTION STYLES */

      blockquote,
      ul,
      ol,
      pre,
      div {
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 23px;
      }

      h1 {
        font-size: 46px;
        max-width: 580px;
        line-height: 1.4;
        padding-left: 0;
        margin-bottom: 30px;
        margin-top: 67px;

        &:first-of-type {
          margin-top: 0;
        }

        @media (max-width: 768px) {
          font-size: 26px;
        }
      }

      em {
        font-style: italic;
      }

      strong {
        font-weight: 700;
      }

      blockquote { 
        font-style: italic;
        padding-left: 20px;
        border-left: 4px solid #333;
      }

      pre {
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      ul {
        padding-left: 20px;
        list-style-type: disc;
      }

      ol {
        padding-left: 20px;
        list-style-type: decimal;
      }
    }


    .payment-status {
      text-align: center;

      p {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    .event-info__map {
      margin-top: 57px;
      position: relative;

      .gmap {
        height: 435px;
      }

      .map-text {
        position: absolute;
        background: rgba(24, 24, 55, 0.8);
        padding: 31px 60px 48px;
        z-index: 1;
        max-width: 370px;
        left: 0;
        top: 0;

        @media (max-width: 768px) {
          max-width: 100%;
          padding: 30px;
        }

        .title {
          color: #fff;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        p {
          color: rgba(255, 255, 255, 0.8);
          font-size: 15px;
          line-height: 1.1;
        }
      }
    }
    
    &__footer {
      background: #f2f2f2;
      padding: 70px 3%;
      text-align: center;

      h5, .organizer-name {
        margin-bottom: 10px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 500;
        font-size: 14px;
      }

      .organizer-logo {
        height: 30px;
        vertical-align: middle;
        margin-right: 10px;
        width: auto;
      }

      h3 {
        color: #000;
        font-weight: 500;
        font-size: 20px;
        margin-top: 11px;
      }

      a {
        display: block;
        margin: 10px 0 0;
        font-weight: bold;
        color: #4b4b4b;
      }
    }
  }

  .event-registration-form {
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 30px;

    h4 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    h6 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
      border-bottom: none;
      position: relative;

      .remove-attendee {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 18px;
        color: #000;
        background: #fff;
        border: 2px solid #000;
        padding: 8px 12px;
        transition: 0.2s;
        font-size: 16px;

        &:hover {
          background: #000;
          color: #ffffff;
        }
      }
    }

    .ticket-form {
      margin-bottom: 20px;

      .ticket-questions {
        margin-bottom: 20px;
        .question {
          margin-bottom: 20px;

          .help-text {
            color: grey;
            margin-top: 5px;

          }
        }
      }

      .add-new {
        padding: 0 20px;
        display: flex;
        margin: 0 auto;
      }
    }

    .buying-btn {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .event-cover {
      &__title {
        padding-top: 40px;

        .date-location {
          font-size: 24px;
        }

        .event-name {
          font-size: 44px;
        }
      }

      &__buying {
        .buying-rigth-side {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .buying-price {
            margin: 0 0 10px;
            .currency {
              font-size: 24px;
            }

            .price {
              font-size: 40px;
            }
          }
        }

        &.multiple-tickets {
          .row {
            flex-wrap: wrap;

            .right-side,
            .left-side {
              flex-basis: 100%;
              max-width: 100%;
            }

            .left-side {
              text-align: left;
            }

            .right-side {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .ticket-price {
                width: auto;
                margin: 0;
              }
            }
          }

          .buying-submit {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;

            .total-price {
              margin: 0 0 10px;
              text-align: center;
            }

            .buying-submit-btn {
              margin: 0;
            }
          }
        }
      }
    }

    .event-info {
      &__footer {
        padding: 20px 3%;
      }
    }
  }

  @media (max-width: 576px) {
    .event-cover {
      &__title {
        padding-top: 20px;

        .date-location {
          font-size: 18px;
        }

        .event-name {
          font-size: 32px;
        }
      }
    }
  }
}